import React from 'react';

import { useHistory } from 'react-router-dom';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { useDimensions } from '@alacaza/core/src/hooks';
import { Box, Text, PostCard } from '@alacaza/core/src/components';
import { postCategories } from 'js/pages/newsfeed/publications/publicationCategories';
import { ReactComponent as IconMarket } from '../../../../img/icons/newsfeed/post-type-market.svg';

const NewPostTypeSelector = ({ setPostBase }) => {
  const dispatch = useRematchDispatch();
  const history = useHistory();
  const { isMobile, windowHeight } = useDimensions();

  return (
    <Box
      h={isMobile ? windowHeight : 'auto'}
      overflow={isMobile ? 'scroll' : 'visible'}
    >
      <div className="modal-header modal-header-market">
        <div className="modal-title">Nouvelle Publication</div>
      </div>
      <Box pb={'xl'} pt="lg" px={isMobile ? 'sm' : '3xl'}>
        <Text
          color="purpleGrey"
          fontSize="md"
          py="lg"
          px="md"
          textAlign="center"
        >
          Choisissez un type de publication :
        </Text>
        <Box flexDir="row" flexWrap="wrap">
          {postCategories.map((postBase) => (
            <div style={{ width: '50%' }} className="cursor-pointer">
              <PostCard
                title={postBase.typeTitle}
                Icon={postBase.icon}
                description={postBase.description}
                handlePress={() => setPostBase(postBase)}
              />
            </div>
          ))}
          <div style={{ width: '50%' }} className="cursor-pointer">
            <PostCard
              title={'Petites annonces'}
              Icon={IconMarket}
              description={
                'Trouvez et échangez des biens ou des services avec vos voisins'
              }
              handlePress={() => {
                dispatch.app.openModal({
                  from: history.location.pathname,
                  to: '/market/new',
                  type: 'NEW_PRODUCT',
                });
                history.push('/market/new');
              }}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default NewPostTypeSelector;
