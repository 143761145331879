import React from 'react';
import { useLocation , Link } from 'react-router-dom';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import { ReactComponent as SuccessIcon } from 'img/icons/svg/success.svg';

interface IProps {
  onClose: () => void;
}
const Success = ({ onClose }: IProps) => {
  const location = useLocation();

  return (
    <>
      <div className="modal-body modal-body-market pt-10">
        <div className="w-full text-align-center mb-12">
          <SuccessIcon />
        </div>
        <div className="w-full text-align-center mb-12">
          {/* @ts-ignore */}
          {location.state?.message}
        </div>
        <div className="w-full text-align-center">
          {/* @ts-ignore */}
          {location.state?.backTo ? (
            /* @ts-ignore */
            <Link to={`${location.state.backTo} `} onClick={onClose}>
              <PlainButton variant="primary" title="Fermer" />
            </Link>
          ) : (
            <PlainButton
              variant="primary"
              title="Fermer"
              onPress={onClose}
              w={'100%'}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Success;
