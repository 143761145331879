import React, { useState, useEffect } from 'react';
import { Box, Stack } from '@alacaza/core/src/components';
import { useDimensions } from '@alacaza/core/src/hooks';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import {
  getPostDetails,
} from '@alacaza/core/src/api/newsfeed';
import { PostDto } from '@alacaza/core/src/typings';
import { toast } from 'react-toastify';
import BlockUi from 'react-block-ui';
import BasicPostForm from './postForms/basicPostForm';
import EventPostForm from './postForms/eventPostForm';
import PollPostForm from './postForms/pollPostForm';
import PollChoiceForm from './postForms/pollChoiceForm';
import SecurityPostForm from './postForms/securityPostForm';
import PhotoPicker from '../../../components/PhotoPicker';

interface IProps {
  postType: string;
  postId: string;
  onClose?: () => void;
}

const renderEditForm = ({ postType, postDetails, onClose }) => {
  if (['ADVICE', 'GENERAL', 'SECURITY'].includes(postType)) {
    return <BasicPostForm isEdit postDetails={postDetails} onClose={onClose} />;
  }
  if (postType === 'EVENT') {
    return <EventPostForm isEdit postDetails={postDetails} onClose={onClose} />;
  }
  if (postType === 'POLL') {
    return <PollPostForm isEdit postDetails={postDetails} onClose={onClose} />;
  }
  if (postType === 'LOST_FOUND') {
    return (
      <SecurityPostForm isEdit postDetails={postDetails} onClose={onClose} />
    );
  }
};

const EditPost: React.FC<IProps> = ({ postId, postType, onClose }) => {
  const dispatch = useRematchDispatch();

  const { isMobile, windowHeight } = useDimensions();
  const [tab, setTab] = React.useState<'info' | 'photo' | 'poll'>('info');
  const [postDetails, setPostDetails] = React.useState<null | PostDto>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async function getPost() {
      const { data } = await getPostDetails({ postId });
      setPostDetails(data);
    })();
  }, []);

  const addImage = (file: File, postId: string) =>
    dispatch.newsfeed.addPostImage({
      postId,
      file,
    });

  const removeImage = (postId, thumbnailFilename) =>
    dispatch.newsfeed.deletePostImage({
      postId,
      thumbnailFilename,
    });

  return (
    <Box
      p="lg"
      pt="xl"
      h={isMobile ? windowHeight : undefined}
      minH={isMobile ? undefined : 600}
      minW={isMobile ? undefined : 500}
      maxW={550}
      overflow={'scroll'}
      maxH={!isMobile ? windowHeight * 0.8 : undefined}
    >
      <ul className="flex column-span-2 row-start-1">
        <li
          className={`f3-xs px-2transition-1 py-2 roboto f2 mr-5 cursor-pointer ${
            tab === 'info'
              ? 'bw-0 text-orange-dark font-bold bb-w-3 bs-solid bc-orange-dark'
              : 'text-purple-grey'
          } `}
          onClick={() => setTab('info')}
        >
          Ma publication
        </li>
        {postType === 'POLL' && (
          <li
            className={`f3-xs px-2transition-1 py-2 roboto f2 mr-5 cursor-pointer ${
              tab === 'poll'
                ? 'bw-0 text-orange-dark font-bold bb-w-3 bs-solid bc-orange-dark'
                : 'text-purple-grey'
            } `}
            onClick={() => setTab('poll')}
          >
            Sondage
          </li>
        )}
        <li
          className={`f3-xs px-2transition-1 py-2 roboto f2 mr-5 cursor-pointer ${
            tab === 'photo'
              ? 'bw-0 text-orange-dark font-bold bb-w-3 bs-solid bc-orange-dark'
              : 'text-purple-grey'
          } `}
          onClick={() => setTab('photo')}
        >
          Photos
        </li>
      </ul>
      {tab === 'info' &&
        postDetails &&
        renderEditForm({ postType, postDetails, onClose })}
      {tab === 'poll' && postDetails && (
        <Box mt={20}>
          <BlockUi blocking={isLoading}>
            <Stack flexDir="row" spacing={20} flexWrap="wrap" w="100%">
              <PollChoiceForm
                postDetails={postDetails as PostDto}
                onClose={onClose}
              />
            </Stack>
          </BlockUi>
        </Box>
      )}
      {tab === 'photo' && postDetails && (
        <Box mt={20}>
          <BlockUi blocking={isLoading}>
            <Stack flexDir="row" spacing={20} flexWrap="wrap">
              {postDetails.images?.map((img, index) => (
                <Box key={index} mb={20}>
                  <PhotoPicker
                    // @ts-ignore
                    source={img.thumbnail ? img.thumbnail : img}
                    onRemove={async () => {
                      setIsLoading(true);
                      try {
                        await removeImage(postId, img.thumbnail);
                        toast.success('Votre image a bien été supprimée');
                      } catch (e) {
                        // do nothing
                      }
                      setIsLoading(false);
                    }}
                  />
                </Box>
              ))}
              <Box>
                <PhotoPicker
                  onChange={async (file) => {
                    setIsLoading(true);
                    try {
                      await addImage(file, postId);
                      toast.success('Votre image a bien été ajoutée');
                    } catch (e) {
                      // do nothing
                    }
                    setIsLoading(false);
                  }}
                />
              </Box>
            </Stack>
          </BlockUi>
        </Box>
      )}
    </Box>
  );
};

export default EditPost;
