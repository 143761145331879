import React from 'react';
import { ReactComponent as PrivateRoomIcon } from 'img/icons/room/private.svg';
import { ReactComponent as ArrowLeftIcon } from 'img/icons/svg/arrow-left.svg';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import BlockUi from 'react-block-ui';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import { UserContactDto } from '@alacaza/core/src/typings';
import { useSelector } from 'react-redux';
import { DirectorySelectors } from '@alacaza/core/src/redux';
import { useDimensions } from '@alacaza/core/src/hooks';
import { residentType } from 'js/pages/settings/constants';
import { Box } from '@alacaza/core/src/components';
import { Log } from '@alacaza/core/src/utils/log';
import RoomMembersInviteItem from './inviteItem';
import SearchBar from '../searchBar';
import { NewRoomData } from './NewRoom';

interface Props {
  roomData: NewRoomData;
  showLoader: boolean;
  handleChange: (e) => void;
  handleSubmit: (e) => void;
}

const logger = new Log();

const NewRoomInviteMembers = ({
  roomData,
  showLoader,
  handleChange,
  handleSubmit,
}: Props) => {
  const dispatch = useRematchDispatch();
  const [selectedUsers, setSelectedUsers] = React.useState<UserContactDto[]>(
    [],
  );
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [residentTypeFilter, setResidentTypeFilter] = React.useState<string>();
  const [
    isMemberOfCondominiumCouncilFilter,
    setIsMemberOfCondominiumCouncilFilter,
  ] = React.useState<boolean>();
  const [searchResults, setSearchResults] = React.useState<UserContactDto[]>(
    [],
  );
  const searchUsersResults = useSelector(DirectorySelectors.getSearchResults);
  const searchUsersLoading = useSelector(
    DirectorySelectors.getSearchResultsLoading,
  );

  const canBeMemberOfCondominiumCouncil = ['OWNER', 'HOMEOWNER'].includes(
    residentTypeFilter,
  );

  React.useEffect(() => {
    setSearchResults(searchUsersResults);
  }, [searchUsersResults]);

  React.useEffect(() => {
    handleChange({
      ...roomData,
      members: selectedUsers.map((user) => user.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  const { isMobile } = useDimensions();
  return (
    <>
      <div className="modal-header relative text-align-center bg-white">
        <span
          className="absolute t-5 l-5 cursor-pointer"
          onClick={() => handleChange({ ...roomData, action: 'SET_ROOM_INFO' })}
        >
          <ArrowLeftIcon className="mr-2" />
        </span>

        <PrivateRoomIcon className="block my-auto" />
        <div className="text-align-center mt-1">
          <div className="block">
            <span className="modal-subtitle">{roomData.title}</span>
          </div>
          <span className="f5 font-normal text-purple-grey block mt-1">
            {roomData.description}
          </span>
        </div>
      </div>

      <BlockUi tag="div" blocking={showLoader} style={{ overflowY: 'auto' }}>
        <Box
          flexDir="column"
          flex={1}
          maxH={!isMobile ? 500 : undefined}
          p="xl"
        >
          <Box flex={1}>
            <Box>
              <div className="f4 text-purple-dark mb-2">
                Recherchez ci-dessous les voisins que vous souhaitez ajouter au
                groupe de discussion.
              </div>
            </Box>
            <div className="f5 text-purple-grey mb-4">
              Ils seront ajoutés automatiquement au groupe et auront accès à
              l'historique de la conversation.
            </div>
            <div className="flex items-center mb-5 mb-2-md">
              <SearchBar
                placeholder={'Rechercher...'}
                handleSubmit={() =>
                  dispatch.directory.searchUsers({
                    query: searchValue,
                    residentTypeFilter,
                    isMemberOfCondominiumCouncilFilter,
                  })
                }
                activateOnChange={true}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                isLoading={searchUsersLoading}
              />
            </div>

            <Box
              alignItems={'center'}
              justifyContent={'space-between'}
              flexDir={'row'}
              py={'md'}
            >
              <div className="flex flex-col">
                <span className="text-purple-grey f4">
                  Typologie des résidents
                </span>

                <select
                  className="p-3 bc-grey f4 mb-1 w-full"
                  name="residentType"
                  onChange={(e) => {
                    setResidentTypeFilter(e.target.value);
                    dispatch.directory.searchUsers({
                      query: searchValue,
                      residentTypeFilter: e.target.value,
                      isMemberOfCondominiumCouncilFilter,
                    });
                  }}
                >
                  <option key="" value=""></option>
                  {residentType.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>
              {canBeMemberOfCondominiumCouncil && (
                <li className="py-2 flex flex-row items-center cursor-pointer transition-1 hover:bg-white">
                  <span className="f4 text-purple flex-grow-1">
                    Membre du conseil syndical
                  </span>
                  <label className="toggle mx-2 inline-flex justify-end">
                    <input
                      type="checkbox"
                      name="isMemberOfCondominiumCouncil"
                      onChange={(e) => {
                        setIsMemberOfCondominiumCouncilFilter(
                          e.target.checked || undefined,
                        );
                        dispatch.directory.searchUsers({
                          query: searchValue,
                          residentTypeFilter,
                          isMemberOfCondominiumCouncilFilter:
                            e.target.checked || undefined,
                        });
                      }}
                    />
                    <span className="slider" />
                  </label>
                </li>
              )}
            </Box>

            <Box overflow="scroll">
              {searchResults?.map((result) => (
                <Box pr="md">
                  <RoomMembersInviteItem
                    key={result.id}
                    user={result}
                    setSelectedUsers={setSelectedUsers}
                    selectedUsers={selectedUsers}
                  />
                </Box>
              ))}

              {!!selectedUsers.length && (
                <Box flex={3} pb="xl">
                  <p className="f4 text-purple-grey mt-5">
                    Membres à ajouter :
                  </p>
                  <Box overflow="scroll">
                    {selectedUsers.map((user) => (
                      <Box pr="md">
                        <RoomMembersInviteItem
                          key={user.id}
                          user={user}
                          setSelectedUsers={setSelectedUsers}
                          selectedUsers={selectedUsers}
                          selectedListMode={true}
                        />
                      </Box>
                    ))}

                    <PlainButton
                      title="Ajouter"
                      onPress={async (e) => {
                        try {
                          await handleSubmit(e);
                        } catch (err) {
                          logger.error('cannot invite room member', err);
                        }
                      }}
                      disabled={selectedUsers.length < 1}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </BlockUi>
    </>
  );
};

export default NewRoomInviteMembers;
