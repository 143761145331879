import React from 'react';
import { Route, Switch } from 'react-router';
import { Box } from '@alacaza/core/src/components';
import { useDimensions } from '@alacaza/core/src/hooks';
import { removeFinalSlash } from '@alacaza/core/src/utils/string';
import InviteProperty from './inviteProperty';
import InviteHome from './inviteHome';

type InviteProps = {
  navigation: any;
};

const Invite = ({ navigation = false }: InviteProps) => {
  const { isMobile } = useDimensions();
  const mobileHeight = window.innerHeight;

  return (
    <Box
      bg="greyLight"
      h={isMobile ? `${mobileHeight  }px` : '80vh'}
      w={isMobile ? '100vw' : '60vh'}
      maxW={600}
      overflow={'scroll'}
      flexDir="column"
    >
      <Switch>
        <Route
          path={`${removeFinalSlash(navigation.from)}/invite`}
          exact
          component={InviteHome}
        />
        <Route
          path={`${removeFinalSlash(
            navigation.from,
          )}/invite/property/id/:propertyId`}
          component={InviteProperty}
        />
        {/* <Route path="/invite/zone/:zoneId" component={InviteZone} /> */}
      </Switch>
    </Box>
  );
};

export default Invite;
