import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@alacaza/core/src/components';
import { useDimensions } from '@alacaza/core/src/hooks';
import NewPostTypeSelector from './NewPostTypeSelector';
import NewPostEditor from './newPostEditor';
import NewPostPublished from './newPostPublished';
import { postCategoryObj } from '../../../pages/newsfeed/publications/publicationCategories';

interface IProps {
  // onClose: () => void
}

const NewPost: React.FC<IProps> = () => {
  const [postBase, setPostBase] = useState<postCategoryObj>();
  const [postPublished, setPostPublished] = useState(false);
  const { isMobile, windowHeight } = useDimensions();
  const modalHeight = useRef<HTMLDivElement>(null);
  const [needOverflow, setNeedOverFlow] = useState(false);

  useEffect(() => {
    if (modalHeight.current) {
      const testHeight = modalHeight.current.offsetHeight > windowHeight * 0.9;
      setNeedOverFlow(testHeight);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getScreen = () => {
    if (postBase && postPublished) return <NewPostPublished />;
    if (postBase)
      return (
        <NewPostEditor
          postBase={postBase}
          setPostBase={setPostBase}
          setPostPublished={setPostPublished}
        />
      );
    return <NewPostTypeSelector setPostBase={setPostBase} />;
  };

  return (
    <div ref={modalHeight}>
      <Box
        h={isMobile ? '100%' : undefined}
        minH={isMobile ? undefined : 600}
        minW={isMobile ? undefined : 500}
        maxW={550}
        justifyContent={postBase ? 'space-between' : 'flex-start'}
        overflow={needOverflow ? 'scroll' : 'visible'}
        maxH={needOverflow && !isMobile ? windowHeight * 0.8 : undefined}
      >
        {getScreen()}
      </Box>
    </div>
  );
};

export default NewPost;
