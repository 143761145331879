import React from 'react';
import { Box, Text } from '@alacaza/core/src/components';
import { ReactComponent as Done } from 'img/icons/svg/done.svg';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';

const NewPostPublished = () => {
  const dispatch = useRematchDispatch();
  return (
    <Box justifyContent="center" alignItems="center" p="2xl" h="100%">
      <Done />
      <Text color="purpleDark" pt="2xl" fontSize="lg">
        Votre publication a bien été prise en compte !
      </Text>
      <Box w={300}>
        <PlainButton
          fullWidth
          title="Fermer"
          mt="xl"
          onPress={() => dispatch.app.closeModal()}
        />
      </Box>
    </Box>
  );
};

export default NewPostPublished;
