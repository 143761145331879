import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import React, { useState } from 'react';
import Input from 'js/components/Input';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { mixpanel } from '@alacaza/core/src';
import { useDimensions } from '@alacaza/core/src/hooks';
import { Box, Stack, Text } from '@alacaza/core/src/components';
import { PostDto } from '@alacaza/core/src/typings';
import { useHistory, useLocation } from 'react-router';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import BlockUi from 'react-block-ui';
import TextareaAutosize from 'react-textarea-autosize';
import PhotoPicker from '../../../../components/PhotoPicker';
import handleFormError from 'js/utils/handleFormError';

type BasicPostFormProps = {
  isEdit?: boolean;
  postDetails?: PostDto;
  postType?: string;
  onClose?: () => void;
  setPostPublished?: (arg: boolean) => void;
  setPostBase?: () => void;
};

const BasicPostForm: React.FC<BasicPostFormProps> = ({
  isEdit,
  postDetails,
  postType,
  setPostBase,
  setPostPublished,
  onClose,
}) => {
  const dispatch = useRematchDispatch();
  const { isMobile } = useDimensions();
  const [images, setImages] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bodyLength, setBodyLength] = useState(postDetails?.body?.length || 0);
  const [titleLength, setTitleLength] = useState(
    postDetails?.title?.length || 0,
  );

  const finish = () => {
    if (isEdit) {
      onClose();
    } else {
      setPostPublished(true);
    }
  };

  const schema = yup.object().shape({
    title: yup
      .string()
      .required('Titre de publication requis')
      .min(3, 'Le titre de la publication doit faire au moins 3 caractères.'),
    body: yup
      .string()
      .required('Texte de publication requis')
      .min(10, 'Le texte de publication doit contenir au moins 10 caractères.'),
  });

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      title: postDetails?.title ?? '',
      body: postDetails?.body ?? '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (isEdit) {
        await dispatch.newsfeed.updatePost({ ...data, id: postDetails.id });
        mixpanel.track('Update post', {
          postType,
          postData: data,
          postId: postDetails?.id,
        });
      } else {
        await dispatch.newsfeed.createPost({
          ...data,
          images,
          postType,
        });
        mixpanel.track('Add new post', {
          postType,
          postData: data,
        });
      }
      finish();
    } catch (e) {
      handleFormError(e);
    }
    setIsLoading(false);
  };

  return (
    <Box justifyContent="space-between" h={isMobile ? '75%' : undefined}>
      <BlockUi blocking={isLoading}>
        <Box p="lg" pt="2xl">
          <Box justifyContent="space-between" flexDir="row">
            <Text color="purpleGrey" fontSize="md" pb="sm">
              Titre de votre publication*
            </Text>
            <Text color="purpleGrey" fontSize="md" pb="sm">
              {titleLength}/100
            </Text>
          </Box>
          <Input
            placeholder="Votre titre..."
            name="title"
            hasError={!!errors.title}
            errorMessage={errors.title?.message}
            ref={register({ required: true })}
            maxLength={100}
            onChange={(e) => setTitleLength(e.currentTarget.value.length)}
          />
          <Box justifyContent="space-between" flexDir="row">
            <Text color="purpleGrey" fontSize="md" pb="sm">
              Publication*
            </Text>
            <Text color="purpleGrey" fontSize="md" pb="sm">
              {bodyLength}/800
            </Text>
          </Box>
          <div className="form-control">
            <TextareaAutosize
              placeholder="Votre publication..."
              onChange={(e) => {
                setBodyLength(e.target.value.length);
              }}
              ref={register({ required: true })}
              minRows={3}
              minLength={3}
              maxLength={800}
              name="body"
            />
            {!!errors.body && errors.body?.message && (
              <Text color="red" fontSize="sm" mb={0} mt={0}>
                {errors.body?.message}
              </Text>
            )}
          </div>
          {!isEdit && (
            <Box mt={20} mb={40}>
              <Text color="purpleGrey" fontSize="md" pb="sm">
                Images
              </Text>
              <Stack flexDir="row" spacing={20} flexWrap="wrap">
                {images.map((img, index) => (
                  <Box key={index} mb={20}>
                    <PhotoPicker
                      source={img}
                      onChange={(file) => {
                        const newImages = [...images];
                        newImages[index] = file;
                        setImages(newImages);
                      }}
                      onRemove={() =>
                        setImages(
                          images.filter(
                            (item, itemIdx) => item && itemIdx !== index,
                          ),
                        )
                      }
                    />
                  </Box>
                ))}
                {images.length < 5 && (
                  <Box>
                    <PhotoPicker
                      onChange={(file) => {
                        setImages([...images, file]);
                      }}
                    />
                  </Box>
                )}
              </Stack>
            </Box>
          )}
        </Box>
        <Box p="lg" flexDir="row">
          <Box flex={1} px="lg">
            <PlainButton
              title="Annuler"
              fullWidth
              variant="secondary"
              onPress={() => (!isEdit ? setPostBase() : onClose!())}
            />
          </Box>
          <Box flex={1} px="lg">
            <PlainButton
              title={postDetails?.title ? 'Valider' : 'Publier'}
              fullWidth
              onPress={handleSubmit(onSubmit)}
            />
          </Box>
        </Box>
      </BlockUi>
    </Box>
  );
};

export default BasicPostForm;
