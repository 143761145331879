import React from 'react';
import { Box } from '@alacaza/core/src/components';
import { toast } from 'react-toastify';

type CopyLinkProps = {
  link: string;
  loading: boolean;
};
const CopyLink = ({ link, loading }: CopyLinkProps) => (
    <Box>
      {loading && <p>LOADER</p>}
      {!loading && link && (
        <div className="bg-greyViolet">
          <p className="text-purple f6 text-align-center bg-grey-violet m-0 py-4 br-top br-5">
            {link}
          </p>
          <button
            className="bg-purple-grey bw-0 text-white py-3 br-bottom br-5 w-full cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(link);
              toast.success('Lien copié dans le presse papier');
            }}
          >
            Copier le lien
          </button>
        </div>
      )}
    </Box>
  );

export default CopyLink;
