import React, { useEffect, useMemo } from 'react';
import { Switch, Route, useHistory, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppSelectors } from '@alacaza/core/src';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { removeFinalSlash } from '@alacaza/core/src/utils/string';
import NewPrivateChatContactSearch from './chat/NewPrivateChatContactSearch';

import NewProduct from './market/NewProductForm';
import ContactSeller from './market/ContactSeller';
import Success from './success';
import DeclareProductAsSold from './market/DeclareProductAsSold';
import UpdateProduct from './market/UpdateProduct';
import DeleteProduct from './market/DeleteProduct';
import Profile from './profile';
import Invite from './invite';
import NewRoom from '../room/NewRoom';
import NewPost from './newsfeed/NewPost';
import EditPost from './newsfeed/EditPost';
import Welcome from './welcome';
import DeletePost from './newsfeed/DeletePost';

const Modal = () => {
  const dispatch = useRematchDispatch();
  const { isOpen, navigation, data } = useSelector(AppSelectors.getModal);
  const history = useHistory();
  const routes = useMemo(() => [
      '/property/rooms/new',
      '/property/rooms/id/:roomId/new',
      `/property/rooms/new/chat`,
      `/property/rooms/id/:roomId/new/chat`,
      `/messages/new`,
      `/messages/id/:discussionId/new`,
      `${removeFinalSlash(navigation.from!)}/invite`,
      `${removeFinalSlash(navigation.from!)}/invite/property/id/:propertyId`,
      `/market/products/id/${data ? data.product?.id : ''}/contact`,
      `/market/products/id/${data ? data.product?.id : ''}/sold`,
      `/market/products/id/${data ? data.product?.id : ''}/update`,
      `/market/products/id/${data ? data.product?.id : ''}/delete`,
      `/modal/news/posts/id/:id/edit`,
      `/modal/news/posts/id/:id/delete`,
      '/market/new',
      `${removeFinalSlash(navigation.from!)}/success`,
      '/users/:id/profile',
      '/users/:id/chat',
      '/news/new',
      '/welcome',
    ], [navigation, data]);

  useEffect(() => {
    isOpen === true
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden');
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const listener = history.listen((location, action) => {
        if (action === 'POP') {
          if (
            routes.every(
              (route) =>
                !matchPath(location.pathname, {
                  path: route,
                  exact: true,
                }),
            )
          ) {
            dispatch.app.closeModal();
          }
        }
      });

      return listener;
    }

    return undefined;
  }, [dispatch.app, history, isOpen, routes]);

  const onCloseModal = (historyLink = '') => {
    const {from} = navigation;
    if (historyLink) {
      history.push(historyLink);
      dispatch.app.closeModal(historyLink);
    } else {
      history.replace(from!);
      dispatch.app.closeModal(from!);
    }
  };

  if (!isOpen) {
    return (
      <Switch>
        <Route
          path="/welcome"
          render={() => <Welcome onClose={onCloseModal} />}
        />
      </Switch>
    );
  }

  return (
    <div className="modal-wrapper">
      <div className="modal-overlay" onClick={() => onCloseModal()} />
      <div className="modal-content">
        <i className="modal-arrow z-index-9" onClick={() => onCloseModal()} />

        <Switch>
          {/* Create new room */}
          {/* accessible from rooms list and room details in desktop */}
          <Route
            exact
            path={['/property/rooms/new', '/property/rooms/id/:roomId/new']}
            render={() => (
              <NewRoom onClose={onCloseModal} navigation={navigation} />
            )}
          />

          {/* New private chat */}
          {/* accessible from rooms and private chat list and room and private chat details on desktop */}
          <Route
            exact
            path={[
              `/property/rooms/new/chat`,
              `/property/rooms/id/:roomId/new/chat`,
              `/messages/new`,
              `/messages/id/:discussionId/new`,
            ]}
            render={() => <NewPrivateChatContactSearch />}
          />

          <Route
            path={`${removeFinalSlash(navigation.from!)}/invite`}
            render={() => <Invite navigation={navigation} />}
          />

          <Route
            path={`/market/products/id/${data ? data.product?.id : ''}/contact`}
            render={() => <ContactSeller data={data} onClose={onCloseModal} />}
          />

          <Route
            path={`/market/products/id/${data ? data.product?.id : ''}/sold`}
            render={() => (
              <DeclareProductAsSold data={data} onClose={onCloseModal} />
            )}
          />

          <Route
            path={`/market/products/id/${data ? data.product?.id : ''}/update`}
            render={() => <UpdateProduct data={data} onClose={onCloseModal} />}
          />

          <Route
            path={`/market/products/id/${data ? data.product?.id : ''}/delete`}
            render={() => (
              <DeleteProduct
                data={data}
                onClose={onCloseModal}
                navigation={navigation}
              />
            )}
          />

          <Route path="/market/new/" render={() => <NewProduct />} />

          {/* <Route */}
          {/*  path="/property/rooms/message/new/" */}
          {/*  render={() => <SendMessage />} */}
          {/* /> */}

          <Route
            path={`${removeFinalSlash(navigation.from!)}/success`}
            render={() => <Success onClose={onCloseModal} />}
          />

          <Route
            path="/users/:id/profile"
            render={(props) => (
              <Profile
                id={props.match.params.id}
                onClose={onCloseModal}
                from={navigation.from!}
              />
            )}
          />
          <Route
            path="/users/:id/chat"
            render={(props) => (
              <Profile
                id={props.match.params.id}
                onClose={onCloseModal}
                from={navigation.from!}
                defaultAction={'NEW_MESSAGE'}
              />
            )}
          />
          <Route
            path={[`/modal/news/posts/id/:id/edit`]}
            render={(props) => (
              <EditPost
                postType={data.type}
                postId={props.match.params.id}
                onClose={onCloseModal}
              />
            )}
          />
          <Route
            path={`/modal/news/posts/id/:id/delete`}
            render={(props) => (
              <DeletePost
                postId={props.match.params.id}
                onClose={onCloseModal}
              />
            )}
          />

          <Route path="/news/new/" exact render={() => <NewPost />} />

          <Route
            path="/welcome"
            render={() => <Welcome onClose={onCloseModal} />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Modal;
