import React, { useState } from 'react';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { postNewRoom } from '@alacaza/core/src/api/im';
import NewRoomTypeSelect from './NewRoomTypeSelect';
import NewRoomSucceed from './NewRoomSucceed';
import NewRoomInfo from './NewRoomInfo';
import NewRoomInviteMembers from './NewRoomInviteMembers';

interface IProps {
  navigation: any;
  onClose: () => void;
}

export interface NewRoomData {
  roomId: string | null;
  title: string;
  description: string;
  isPublic: boolean;
  members: string[];
  action: 'SELECT_ROOM_TYPE' | 'INVITE_MEMBERS' | 'SET_ROOM_INFO' | 'SUCCEED';
}

const NewRoom = ({ onClose }: IProps) => {
  const dispatch = useRematchDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [roomData, setRoomData] = useState<NewRoomData>({
    roomId: null,
    title: '',
    description: '',
    isPublic: true,
    action: 'SELECT_ROOM_TYPE',
    members: [],
  });

  const setRoomType = (roomType: boolean) => {
    setRoomData({
      ...roomData,
      isPublic: roomType,
      members: [],
      action: roomData.isPublic === true ? 'SET_ROOM_INFO' : 'INVITE_MEMBERS',
    });
  };

  const validRoomInfo = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { data } = await postNewRoom({
        data: {
          title: roomData.title,
          description: roomData.description,
          isPublic: roomData.isPublic,
          invitedUserIds: roomData.members,
        },
      });
      setRoomData({
        ...roomData,
        roomId: data.id,
        action: 'SUCCEED',
      });
      dispatch.xmpp.fetchRoomList();
    } catch (error) {
      // do nothing
    }
  };

  if (roomData.action === 'SELECT_ROOM_TYPE') {
    return <NewRoomTypeSelect handleChange={setRoomType} />;
  }

  if (roomData.action === 'SET_ROOM_INFO') {
    return (
      <NewRoomInfo
        roomData={roomData}
        showLoader={isLoading}
        handleChange={setRoomData}
        handleSubmit={validRoomInfo}
        onClose={onClose}
      />
    );
  }

  if (roomData.action === 'INVITE_MEMBERS') {
    return (
      <NewRoomInviteMembers
        roomData={roomData}
        showLoader={isLoading}
        handleChange={setRoomData}
        handleSubmit={validRoomInfo}
      />
    );
  }

  if (roomData.action === 'SUCCEED') {
    return <NewRoomSucceed roomId={roomData.roomId} onClose={onClose} />;
  }

  // Show room type selection by default
  return <NewRoomTypeSelect handleChange={setRoomType} />;
};

export default NewRoom;
