import React from 'react';
import { Div } from 'react-native-magnus';
import { TouchableWithoutFeedback } from 'react-native';
import Text from '@alacaza/core/src/components/Text';
import { DivProps } from 'react-native-magnus/lib/typescript/src/ui/div/div.type';

interface Props extends DivProps {
  checked?: boolean;
  label: string;
  onCheck?: () => void;
}

const RadioButton = ({ checked, label, onCheck, ...props }: Props) => (
    <TouchableWithoutFeedback onPress={onCheck}>
      <Div alignItems="center" {...props}>
        <Text mx="md" color={checked ? 'orange' : 'purpleDark'}>
          {label}
        </Text>
        <Div
          p={2}
          borderWidth={1}
          borderColor={checked ? 'orange' : 'purpleDark'}
          w={16}
          h={16}
          rounded="circle"
          alignItems="center"
          justifyContent="center"
        >
          {checked && <Div w="100%" h="100%" rounded="circle" bg="orange" />}
        </Div>
      </Div>
    </TouchableWithoutFeedback>
  );

export default RadioButton;
