import React, { useEffect, useState } from 'react';
import { MarketAPI } from '@alacaza/core/src/api';
import { Stack } from '@alacaza/core/src/components';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import { ProductDto ,
  PRODUCT_SOLD_THROUGH_TYPE,
  ProductSoldThroughTypeItemDto,
} from '@alacaza/core/src/typings';
import BlockUi from 'react-block-ui';
import { toast } from 'react-toastify';

interface IProps {
  data: { product?: ProductDto };
  onClose: (link?: string) => void;
}

const DeclareProductAsSold = ({ data, onClose }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductDto>(data.product!);
  const [soldThrough, setSoldThrough] = useState<PRODUCT_SOLD_THROUGH_TYPE>(
    PRODUCT_SOLD_THROUGH_TYPE.ALACAZA,
  );
  const [soldThroughOptions, setSoldThroughOptions] = useState<
    ProductSoldThroughTypeItemDto[]
  >([]);

  useEffect(() => {
    setSoldThroughOptions(MarketAPI.findProductSoldThroughRef());
  }, []);

  const onChangeSoldThrough = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    // @ts-ignore
    setSoldThrough(evt.target.value);
  };

  const onSubmit = () => {
    setIsLoading(true);

    MarketAPI.declareProductAsSold({
      productId: product.id,
      soldThrough,
    })
      .then((response) => {
        setIsLoading(false);
        setProduct(response.data);

        toast.success('Votre annonce a bien été mise à jour !');

        onClose('/market/me');
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="modal-header modal-header-market"></div>

      <div className="modal-body modal-body-market">
        <BlockUi tag="div" blocking={isLoading}>
          {product.category!.productType === 'OBJECT' && (
            <div>
              <div className="f2 roboto font-bold text-align-center">
                Votre bien a été vendu ?
              </div>
              <div className="f4 mt-10">Félicitations ! &#127881;</div>
              <div className="f4 mt-4">
                Dites-nous simplement par quel moyen, parmi ceux proposés
                ci-dessous, puis validez pour mettre à jour le statut de votre
                annonce.
              </div>
            </div>
          )}

          {product.category!.productType === 'SERVICE' && (
            <div>
              <div className="f2 text-purple roboto font-bold text-align-center">
                Votre demande a été comblée ?
              </div>
              <div className="f4 mt-10">Ravis pour vous ! &#129321;</div>
              <div className="f4 mt-4">
                Dites-nous simplement par quel moyen, parmi ceux proposés
                ci-dessous, puis validez pour mettre à jour le statut de votre
                annonce.
              </div>
            </div>
          )}

          <div className="pt-10 mx-auto text-align-center">
            <select
              className="p-3 bc-grey f4 mb-5 w-full"
              onChange={onChangeSoldThrough}
            >
              <option value="">Choisissez une option</option>
              {soldThroughOptions
                .filter(
                  (option) =>
                    product.category!.productType === option.productType,
                )
                .map((option, index) => (
                  <option key={`option-${index}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </div>

          <Stack
            flexDir="row"
            justifyContent="flex-end"
            spacing={16}
            mt={16}
            px={40}
          >
            <PlainButton
              variant="secondary"
              title="Annuler"
              onPress={() => onClose()}
            />
            <PlainButton
              variant="primary"
              title="Valider"
              onPress={onSubmit}
              disabled={!soldThrough || soldThrough.length <= 0}
            />
          </Stack>
        </BlockUi>
      </div>
    </React.Fragment>
  );
};

export default DeclareProductAsSold;
