import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import React, { useState } from 'react';
import Input from 'js/components/Input';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { ReactComponent as CrossIcon } from 'img/icons/svg/cross.svg';
import { mixpanel } from '@alacaza/core/src';
import { useDimensions } from '@alacaza/core/src/hooks';
import { Box, Stack, Text } from '@alacaza/core/src/components';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { PostDto } from '@alacaza/core/src/typings';
import BlockUi from 'react-block-ui';
import PhotoPicker from '../../../../components/PhotoPicker';
import handleFormError from 'js/utils/handleFormError';

const PollPostForm: React.FC<{
  setPostBase?: Function;
  setPostPublished?: Function;
  isEdit?: boolean;
  postDetails?: PostDto;
  onClose?: () => void;
}> = ({ setPostBase, setPostPublished, isEdit, postDetails, onClose }) => {
  const { isMobile } = useDimensions();
  const [images, setImages] = useState<File[]>([]);
  const [bodyLength, setBodyLength] = useState(postDetails?.body?.length || 0);
  const [titleLength, setTitleLength] = useState(
    postDetails?.title?.length || 0,
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useRematchDispatch();

  const schema = yup.object().shape({
    title: yup
      .string()
      .required('Titre de publication requis')
      .min(3, 'Le titre de la publication doit faire au moins 3 charactères.'),
    body: yup
      .string()
      .required('Texte de publication requis')
      .min(10, 'Le texte de publication doit faire au moins 10 caractères.'),
    pollOptions: !isEdit
      ? yup
          .array()
          .of(
            yup
              .object()
              .shape({ label: yup.string().required('Champ requis').min(1) }),
          )
      : yup.array().of(yup.object().notRequired()),
  });

  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      title: postDetails?.title ?? '',
      body: postDetails?.body ?? '',
      pollOptions: [{ label: '' }],
      authorizeMultipleChoices: postDetails?.authorizeMultipleChoices ?? 0,
      authorizeCustomOptions: postDetails?.authorizeCustomOptions ?? 0,
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { fields, remove, append /* prepend, remove, swap, move, insert */ } =
    useFieldArray({
      control,
      name: 'pollOptions',
    });

  const finish = () => {
    if (isEdit) {
      onClose();
    } else {
      setPostPublished(true);
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (isEdit) {
        await dispatch.newsfeed.updatePost({
          body: data.body,
          title: data.title,
          id: postDetails.id,
        });
        mixpanel.track('Update post', {
          postType: 'POLL',
          postData: data,
          postId: postDetails?.id,
        });
      } else {
        await dispatch.newsfeed.createPoll({ ...data, images });
        mixpanel.track('Add new post', {
          postType: 'POLL',
          postData: data,
        });
      }

      finish();
    } catch (e) {
      handleFormError(e);
    }

    setIsLoading(false);
  };

  return (
    <Box justifyContent="space-between" h={isMobile ? '75%' : undefined}>
      <BlockUi blocking={isLoading}>
        <Box p="lg" pt="2xl">
          <Box justifyContent="space-between" flexDir="row">
            <Text color="purpleGrey" fontSize="md" pb="sm">
              Titre de votre Sondage *
            </Text>
            <Text color="purpleGrey" fontSize="md" pb="sm">
              {titleLength}/100
            </Text>
          </Box>
          <Input
            placeholder="Votre titre..."
            name="title"
            hasError={!!errors.title}
            errorMessage={errors.title?.message}
            ref={register({ required: true })}
            maxLength={100}
            onChange={(e) => setTitleLength(e.currentTarget.value.length)}
          />
          <Box justifyContent="space-between" flexDir="row">
            <Text color="purpleGrey" fontSize="md" pb="sm">
              Publication*
            </Text>
            <Text color="purpleGrey" fontSize="md" pb="sm">
              {bodyLength}/800
            </Text>
          </Box>
          <Input
            placeholder="Votre sondage..."
            rows={5}
            multiline
            name="body"
            hasError={!!errors.body}
            errorMessage={errors.body?.message}
            ref={register({ required: true })}
            onChange={(e) => setBodyLength(e.currentTarget.value.length)}
            maxLength={800}
          />

          {!isEdit && (
            <>
              <Box flexDir="row" w="100%" pb="sm">
                <label className="radio-button mr-2">
                  <input
                    type="checkbox"
                    ref={register}
                    name="authorizeCustomOptions"
                  />
                  <span className="radio-background" />
                </label>
                <Text fontSize="md" color="purpleGrey">
                  Autoriser des réponses différentes à celles que je propose
                </Text>
              </Box>
              <Box flexDir="row" w="100%" pb="xl">
                <label className="radio-button mr-2">
                  <input
                    type="checkbox"
                    ref={register}
                    name="authorizeMultipleChoices"
                  />
                  <span className="radio-background" />
                </label>
                <Text fontSize="md" color="purpleGrey">
                  Autoriser plusieurs réponses par personne
                </Text>
              </Box>
              {fields.map((item, index) => (
                <div className="flex justify-between items-center mb-2">
                  <Input
                    containerClassName="w-full mb-0"
                    placeholder="Option"
                    name={`pollOptions[${index}].label`}
                    defaultValue={item.title}
                    ref={register()}
                  />
                  <CrossIcon className="ml-2" onClick={() => remove(index)} />
                </div>
              ))}

              <span
                className="underline cursor-pointer f5"
                onClick={() => append({ title: '' })}
              >
                Ajouter +
              </span>
            </>
          )}
          {!isEdit && (
            <Box mt={20} mb={40}>
              <Text color="purpleGrey" fontSize="md" pb="sm">
                Images
              </Text>
              <Stack flexDir="row" spacing={20} flexWrap="wrap">
                {images.map((img, index) => (
                  <Box key={index} mb={20}>
                    <PhotoPicker
                      source={img}
                      onChange={(file) => {
                        const newImages = [...images];
                        newImages[index] = file;
                        setImages(newImages);
                      }}
                      onRemove={() =>
                        setImages(
                          images.filter(
                            (item, itemIdx) => item && itemIdx !== index,
                          ),
                        )
                      }
                    />
                  </Box>
                ))}
                {images.length < 5 && (
                  <Box>
                    <PhotoPicker
                      onChange={(file) => {
                        setImages([...images, file]);
                      }}
                    />
                  </Box>
                )}
              </Stack>
            </Box>
          )}
        </Box>
        <Box p="lg" flexDir="row">
          <Box flex={1} px="lg">
            <PlainButton
              title="Annuler"
              fullWidth
              variant="secondary"
              onPress={() =>
                !isEdit ? setPostBase && setPostBase() : onClose!()
              }
            />
          </Box>
          <Box flex={1} px="lg">
            <PlainButton
              title={postDetails?.title ? 'Valider' : 'Publier'}
              fullWidth
              onPress={handleSubmit(onSubmit)}
            />
          </Box>
        </Box>
      </BlockUi>
    </Box>
  );
};

export default PollPostForm;
