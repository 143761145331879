import React from 'react';

import UserContactsSearch from '../../searchBar/UserContactsSearch';

const NewPrivateChatContactSearch: React.FC = () => (
    <React.Fragment>
      <div className="modal-header">
        <div className="modal-title">Nouveau message privé</div>
      </div>

      <div className="modal-body min-height-66-vh">
        <div className="f5 text-purple block mb-6 text-align-center">
          Recherchez ci-dessous dans vos contacts le voisin avec lequel vous
          souhaitez discuter
        </div>

        <UserContactsSearch />
      </div>
    </React.Fragment>
  );

export default NewPrivateChatContactSearch;
