import React from 'react';
import { AppSelectors, MeSelectors } from '@alacaza/core/src';
import { useSelector } from 'react-redux';
import inviteIllustration from 'img/people.png';
import { Box } from '@alacaza/core/src/components';
import { removeFinalSlash } from '@alacaza/core/src/utils/string';
import PanelHeader from '../../../components/panelHeader';
import InviteCard from '../../../components/invite/inviteCard';

const InviteHome = () => {
  const account = useSelector(MeSelectors.getCurrentAccount);

  const { navigation } = useSelector(AppSelectors.getModal);

  return (
    <>
      <PanelHeader title={'Inviter des voisins'} />
      <Box flexDir="column" p="md">
        <Box
          flex={1}
          alignItems="center"
          flexDir="column"
          style={{ flexBasis: 'auto' }}
          mt={10}
          px={20}
        >
          {!!account && (
            <>
              <Box w="100%" p="xxl">
                {/* <p className="text-purple-grey f4 text-align-center mb-2"> */}
                {/*  Inviter un voisin dans ma résidence */}
                {/* </p> */}
                <InviteCard
                  name={account.propertyName}
                  to={`${removeFinalSlash(
                    navigation.from!,
                  )}/invite/property/id/${account.propertyId}`}
                  type="PROPERTY"
                />
              </Box>
              {/* <Box py="lg" w="100%" px="xxl"> */}
              {/*  <p className="text-purple-grey f4 text-align-center mb-2"> */}
              {/*    Inviter un voisin à rejoindre mon quartier */}
              {/*  </p> */}
              {/*  <InviteCard */}
              {/*    name={account?.zoneName} */}
              {/*    type="ZONE" */}
              {/*    to={`/invite/zone/${account.zoneId}`} */}
              {/*  /> */}
              {/* </Box> */}
            </>
          )}
        </Box>
        <Box flex={1} p="xl" alignItems="center" style={{ flexBasis: 'auto' }}>
          <img
            src={inviteIllustration}
            alt="inviter des voisins"
            className="w-full"
          />
        </Box>
      </Box>
    </>
  );
};

export default InviteHome;
