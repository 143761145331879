import React, { useEffect, useState, useCallback } from 'react';
import { mixpanel } from '@alacaza/core/src';
import { UserAPI } from '@alacaza/core/src/api';
import { Stack, Text } from '@alacaza/core/src/components';
import { UserContactDto } from '@alacaza/core/src/typings';
import UserIcon from '@alacaza/core/src/components/user/UserIcon';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import IconButton from '@alacaza/core/src/components/buttons/IconButton';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { toast } from 'react-toastify';
import BlockUi from 'react-block-ui';
import { useHistory } from 'react-router';
import { blockedUsersMessages } from '@alacaza/core/src/messages/me';
import Input from '../Input';

interface IProps {
  onClose: () => void;
  id: string;
  from: string;
  jid?: string;
  defaultAction?: 'PROFILE' | 'NEW_MESSAGE' | null;
}

const Profile = ({ onClose, id, defaultAction }: IProps) => {
  const [action, setAction] = useState<
    'PROFILE' | 'NEW_MESSAGE' | 'MESSAGE_SENT'
  >(defaultAction || 'PROFILE');
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<UserContactDto | null>(null);
  const [message, setMessage] = useState('');
  const history = useHistory();

  const onChangeMessage = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(evt.target.value);
  };

  const dispatch = useRematchDispatch();

  const getUserData = useCallback(async () => {
    try {
      const { data } = await UserAPI.getUserProfile(id);
      setUserData(data);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const onSendMessage = () => {
    setIsLoading(true);
    UserAPI.sendMessageToUser({ userId: id, message })
      .then(() => {
        dispatch.xmpp.fetchPrivateDiscussions();
        toast.success('Votre message a bien été envoyé');
        mixpanel.track('Send new private chat message', { toUserId: id });
        setIsLoading(false);
        setAction('PROFILE');
        onClose();
        // override onClose to redirect to /messages (current)
        history.push('/messages');
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Votre message n'a pas pu être envoyé");
      });
  };

  if (action === 'NEW_MESSAGE') {
    return (
      <div className="modal-body items-center">
        <Stack spacing="md" alignItems="center">
          <BlockUi tag="div" blocking={isLoading}>
            <Input
              label={`Votre message à ${userData?.fullName}`}
              rows={2}
              width={'100%'}
              multiline
              onChange={onChangeMessage}
              maxLength={400}
              name="Message"
              value={message}
            />
            <Stack
              flexDir="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={16}
              px={40}
            >
              <PlainButton
                variant="secondary"
                title="Annuler"
                onPress={() => setAction('PROFILE')}
              />

              <PlainButton
                variant="primary"
                disabled={
                  isLoading ||
                  !message ||
                  message.length <= 2 ||
                  message.length > 400
                }
                title="Envoyer"
                onPress={() => onSendMessage()}
              />
            </Stack>
          </BlockUi>
        </Stack>
      </div>
    );
  }

  return (
    <BlockUi tag="div" blocking={isLoading} renderChildren={false}>
      <div className="modal-body items-center">
        <Stack spacing="md" alignItems="center">
          <UserIcon
            image={{ profile: userData?.profileImg, icon: userData?.icon }}
            name={userData?.fullName!}
            type="large"
          />
          <Stack spacing="sm" alignItems="center">
            <Text
              color="purple"
              fontSize="3xl"
              fontWeight="bold"
              fontFamily="Roboto Slab"
            >
              {userData?.fullName}
            </Text>
            <Text color="purpleGrey" fontSize="md">
              {userData?.propertyName}
            </Text>
          </Stack>
        </Stack>

        {userData?.type === 'RESIDENT' && (
          <PlainButton
            title="Envoyer un message privé"
            mt={65}
            onPress={() => setAction('NEW_MESSAGE')}
          />
        )}

        <div className="modal-footer">
          <div className="flex justify-around pt-20 px-10 px-1-xs">
            {userData?.type === 'RESIDENT' && (
              <div className="justify-center">
                <IconButton
                  text={`Bloquer ${userData.shortName}`}
                  icon="user-times"
                  fontSize="sm"
                  onPress={() => {
                    dispatch.blockedUsers
                      .blockUser({ user: userData })
                      .then(() => {
                        toast.success(blockedUsersMessages.blockUserSucceed);

                        setTimeout(() => {
                          dispatch.xmpp.fetchRoomList();
                          dispatch.xmpp.fetchPrivateDiscussions();
                        }, 3000);

                        onClose();
                      });
                  }}
                  color="red"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </BlockUi>
  );
};

export default Profile;
