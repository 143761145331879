import React from 'react';
import { useHistory } from 'react-router';
import { Stack } from '@alacaza/core/src/components';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';

interface IProps {
  data: any;
  onClose: () => void;
  navigation: any;
}

const DeleteProduct = ({ data, onClose, navigation }: IProps) => {
  const dispatch = useRematchDispatch();
  const history = useHistory();
  const onSend = async () => {
    await dispatch.products.deleteProduct({
      productId: data.product.id,
    });

    onClose();
    history.push('/market');
  };

  return (
    <React.Fragment>
      <div className="modal-header modal-header-market">
        <p className="f3 text-purple">
          Voulez-vous vraiment supprimer votre annonce ?
        </p>
      </div>

      <div className="modal-body modal-body-market">
        <Stack
          flexDir="row"
          justifyContent="flex-end"
          spacing={16}
          mt={16}
          px={40}
        >
          <PlainButton variant="secondary" title="Annuler" onPress={onClose} />
          <PlainButton variant="primary" title="Supprimer" onPress={onSend} />
        </Stack>
      </div>
    </React.Fragment>
  );
};

export default DeleteProduct;
