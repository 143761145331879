import React from 'react';

import { ReactComponent as PublicRoomIcon } from 'img/icons/room/public.svg';
import { ReactComponent as PrivateRoomIcon } from 'img/icons/room/private.svg';
import { ReactComponent as ArrowLeftIcon } from 'img/icons/svg/arrow-left.svg';
import BlockUi from 'react-block-ui';
import TextareaAutosize from 'react-textarea-autosize';
import { NewRoomData } from './NewRoom';

interface Props {
  roomData: NewRoomData;
  showLoader: boolean;
  handleChange: (e) => void;
  handleSubmit: (e) => void;
  onClose: () => void;
}

const NewRoomInfo = ({
  roomData,
  showLoader,
  handleChange,
  handleSubmit,
  onClose,
}: Props) => (
    <React.Fragment>
      <div className="modal-header relative text-align-center bg-white">
        <span
          className="absolute t-5 l-5 cursor-pointer"
          onClick={() =>
            handleChange({ ...roomData, action: 'SELECT_ROOM_TYPE' })
          }
        >
          <ArrowLeftIcon className="mr-2" />
        </span>

        {roomData.isPublic === true ? (
          <PublicRoomIcon className="block my-auto" />
        ) : (
          <PrivateRoomIcon className="block my-auto" />
        )}

        <div className="text-align-center mt-3">
          <div className="block">
            <span className="modal-subtitle">
              {roomData.isPublic === true
                ? 'Groupe de discussion public'
                : 'Groupe de discussion privé'}
            </span>
          </div>
          <span className="f5 font-normal text-purple-grey block mt-1">
            {roomData.isPublic === true
              ? 'Tous les voisins de votre résidence auront accès à cette discussion'
              : 'Seuls les voisins que vous invitez auront accès à cette discussion'}
          </span>
        </div>
      </div>

      <BlockUi tag="div" blocking={showLoader}>
        <form onSubmit={handleSubmit}>
          <div className="modal-body max-height-66vh overflow-y-scroll mb-4">
            <span className="f4 text-purple-grey block mb-4">
              Titre du groupe de discussion *
            </span>
            <label className="flex items-center common-input bg-white px-3 br-5 bs-solid bw-1 bc-grey hover:box-shadow-4">
              <input
                className="appearance-0 bw-0 text-purple f4 flex-grow-1 py-3 lh-3 outline-0"
                placeholder="Titre..."
                required
                name="title"
                minLength={3}
                maxLength={100}
                onChange={(e) =>
                  handleChange({ ...roomData, title: e.target.value })
                }
                value={roomData.title}
              />
            </label>

            <div className="flex items-center justify-between mb-4 mt-4">
              <span className="f4 text-purple-grey block">
                Description du groupe de discussion
              </span>
              <span className="f4 text-purple-grey">
                {roomData.description.length} / 400
              </span>
            </div>
            <label className="flex items-center common-input bg-white px-3 br-5 bs-solid bw-1 bc-grey hover:box-shadow-4">
              <TextareaAutosize
                placeholder="Expliquez ici le sujet de ce groupe..."
                className="resize-0 appearance-0 bw-0 text-purple f4 flex-grow-1 py-3 lh-3 outline-0"
                name="description"
                onChange={(e) =>
                  handleChange({ ...roomData, description: e.target.value })
                }
                required
                minRows={4}
                minLength={3}
                maxLength={400}
                value={roomData.description}
              />
            </label>
          </div>

          <div className="modal-footer">
            <div className="flex justify-around px-10 px-3-xs">
              <button className="btn secondary w-full mr-2" onClick={onClose}>
                Annuler
              </button>

              <button
                onClick={(e) => {
                  roomData.isPublic
                    ? handleSubmit(e)
                    : handleChange({ ...roomData, action: 'INVITE_MEMBERS' });
                }}
                className={
                  roomData.title &&
                  roomData.title.length >= 3 &&
                  roomData.title.length < 100 &&
                  roomData.description &&
                  roomData.description.length >= 3 &&
                  roomData.description.length < 400
                    ? 'btn w-full ml-2'
                    : 'btn w-full ml-2 disabled'
                }
              >
                {roomData.isPublic ? 'Valider' : 'Continuer'}
              </button>
            </div>
          </div>
        </form>
      </BlockUi>
    </React.Fragment>
  );

export default NewRoomInfo;
