import React from 'react';
import UserIcon from '@alacaza/core/src/components/user/UserIcon';

const UserCard = ({ user }) => (
    <li className="flex items-center" key={`user-${user.id}`}>
      <UserIcon
        name={user.shortName}
        image={{ icon: user.icon, profile: user.icon }}
        type="normal"
      />
      <span
        className="pl-2 f4 text-purple highlights"
        dangerouslySetInnerHTML={{
          // @ts-ignore
          __html:
            !!user.highlights && !!user.highlights.fullName
              ? user.highlights.fullName
              : user.fullName,
        }}
      />

      {(user.isJanitor || user.isuserOfCondominiumCouncil) &&
        user.role !== 'ADMIN' && (
          <span className="f5 text-orange-dark bg-orange-light px-2 py-1 br-13 order-1-xs mb-1-xs text-align-center ml-1">
            {user.isJanitor && 'Gardien'}
            {user.isuserOfCondominiumCouncil && 'Membre du conseil syndical'}
          </span>
        )}
      {user.role === 'ADMIN' && (
        <span className="f5 text-green-dark bg-green-light px-2 py-1 br-13 order-1-xs mb-1-xs text-align-center ml-1">
          {user.role === 'ADMIN' && 'Admin'}
        </span>
      )}
    </li>
  );

export default UserCard;
