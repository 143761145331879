import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Modal from './components/modal';
import PrivateRoute from './PrivateRoute';
import { Box } from '@alacaza/core/src/components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import LoaderIcon from '../img/loader.gif';
import DownloadApp from './components/invite/download-app';

const Login = React.lazy(() => import('./pages/login'));
const Main = React.lazy(() => import('./Main'));
const MagicLinkLogin = React.lazy(() => import('./pages/magic-link/login'));
const MagicLinkRequestForm = React.lazy(
  () => import('./pages/magic-link/index'),
);
const ValidEmailUpdateRequest = React.lazy(() => import('./pages/email/valid'));
const LostPasswordForm = React.lazy(() => import('./pages/password/index'));
const ResetPasswordForm = React.lazy(() => import('./pages/password/reset'));

const App = () => (
    <Suspense
      fallback={
        <BlockUi
          tag="div"
          className="w-full h-full"
          blocking={true}
          loader={<></>}
          keepInView={true}
        >
          <Box
            flex={1}
            alignItems="center"
            justifyContent="center"
            style={{ height: '100%' }}
          >
            <img src={LoaderIcon} alt="Alacaza" />
          </Box>
        </BlockUi>
      }
    >
      <div className="flex flex-1 flex-col">
        <Switch>
          <Route path="/login/magic-link" component={MagicLinkLogin} />
          <Route path="/magic-link/request" component={MagicLinkRequestForm} />
          <Route path="/login" component={Login} />
          <Route path="/password/index" exact component={LostPasswordForm} />
          <Route path="/password/reset" component={ResetPasswordForm} />
          <Route path="/email/valid" component={ValidEmailUpdateRequest} />

          <PrivateRoute path="/">
            <Main />
          </PrivateRoute>
        </Switch>

        <Modal />

        <ToastContainer position="top-center" autoClose={3000} />

        <DownloadApp />
      </div>
    </Suspense>
  );

export default App;
