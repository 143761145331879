import React from 'react';
import { MeSelectors } from '@alacaza/core/src';
import { useDimensions } from '@alacaza/core/src/hooks';
import { Box, Text } from '@alacaza/core/src/components';
import { ReactComponent as ArrowLeftIcon } from 'img/icons/svg/arrow-left.svg';
import { useSelector } from 'react-redux';
import { postCategoryObj } from 'js/pages/newsfeed/publications/publicationCategories';
import BasicPostForm from './postForms/basicPostForm';
import EventPostForm from './postForms/eventPostForm';
import SecurityPostForm from './postForms/securityPostForm';
import PollPostForm from './postForms/pollPostForm';

export type NewPostEditorProps = {
  postBase: postCategoryObj;
  setPostBase: any;
  setPostPublished: any;
};

const NewPostEditor = ({
  postBase,
  setPostBase,
  setPostPublished,
}: NewPostEditorProps) => {
  const {
    icon: PostBaseICon,
    typeTitle: postBaseTitle,
    description: postBaseBody,
  } = postBase;
  const me = useSelector(MeSelectors.getMeInfo);
  const account = me && me.accounts?.find((account) => account.isMainAccount);
  const { isMobile, windowHeight } = useDimensions();

  const getPostForm = () => {
    if (['ADVICE', 'GENERAL', 'SECURITY'].includes(postBase.type.toUpperCase()))
      return (
        <BasicPostForm
          setPostPublished={setPostPublished}
          setPostBase={setPostBase}
          postType={postBase.type}
        />
      );
    if (postBase.type.toUpperCase() === 'EVENT')
      return (
        <EventPostForm
          setPostPublished={setPostPublished}
          setPostBase={setPostBase}
        />
      );
    if (postBase.type.toUpperCase() === 'LOST_FOUND')
      return (
        <SecurityPostForm
          setPostPublished={setPostPublished}
          setPostBase={setPostBase}
        />
      );
    if (postBase.type.toUpperCase() === 'POLL') {
      return (
        <PollPostForm
          setPostPublished={setPostPublished}
          setPostBase={setPostBase}
        />
      );
    } return (
        <Box h="50vh" alignItems="center" justifyContent="center">
          <Text color="purple">Coming soon...</Text>
        </Box>
      );
  };

  return (
    <Box
      h={isMobile ? windowHeight : 'auto'}
      overflow={isMobile ? 'scroll' : 'visible'}
    >
      <Box bg="white" p="lg" pt="xl">
        <ArrowLeftIcon
          className="mr-6 cursor-pointer absolute l-5;
          t-7 z-index-9"
          onClick={() => setPostBase()}
        />
        <Box alignItems="center">
          <PostBaseICon height={40} width={40} />
          <p className="text-purple m-0 p-2 f1 roboto text-align-center">
            {postBaseTitle}
          </p>
          <p className="text-purple-grey m-0 f5"> {account?.propertyName}</p>
          <p className="text-purple-grey m-0 f5 pt-4 pb-2"> {postBaseBody}</p>
        </Box>
      </Box>
      {getPostForm()}
    </Box>
  );
};

export default NewPostEditor;
