import React, { useEffect, useReducer, useState } from 'react';
import { MarketAPI } from '@alacaza/core/src/api';
import { Box, Stack, Text } from '@alacaza/core/src/components';
import { PRODUCT_TYPE, ProductCategoryDto } from '@alacaza/core/src/typings';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import {
  imageDelete,
  imageUpload,
  priceError,
  productUpdate,
} from '@alacaza/core/src/messages/market';
import BlockUi from 'react-block-ui';
import styled, { keyframes } from 'styled-components';
import PhotoPicker from '../../../components/PhotoPicker';
import RadioButton from '../../../components/RadioButton';

import Input from '../../../components/Input';

interface IProps {
  data: any;
  onClose: () => void;
}

interface IState {
  checked: 'objects' | 'services';
  serviceType: 'REQUEST' | 'OFFER';
  images: File[];
  isGift: boolean;
  selectedCategoryId: ProductCategoryDto['id'];
  categories: ProductCategoryDto[];
  price: number | null;
  title: string;
  description: string;
  priceType: 'PER_HOUR' | 'PER_DAY' | 'ON_REQUEST' | 'IS_GIFT' | null;
}

type Action =
  | { type: 'CHECK_TYPE'; payload: IState['checked'] }
  | { type: 'SERVICE_TYPE'; payload: IState['serviceType'] }
  | { type: 'ADD_IMAGE'; payload: File }
  | { type: 'UPDATE_IMAGE'; payload: { index: number; file: File } }
  | { type: 'REMOVE_IMAGE'; payload: number }
  | { type: 'CHANGE_PRICE'; payload: number }
  | { type: 'CHANGE_PRICE_TYPE'; payload: IState['priceType'] }
  | { type: 'SET_CATEGORIES'; payload: ProductCategoryDto[] }
  | { type: 'SELECT_CATEGORY'; payload: ProductCategoryDto['id'] }
  | { type: 'RESET'; payload?: undefined }
  | { type: 'SET_IS_GIFT'; payload: boolean }
  | { type: 'SET_TITLE'; payload: string }
  | { type: 'SET_DESCRIPTION'; payload: string };

const tab = keyframes`
  0%{
    opacity: 0;
    transform: translate3d(-20px,0,0);
  }
  100%{
    opacity: 1;
    transform: translate3d(0,0,0);
  }
`;

export const TabAnim = styled.div`
  animation: ${tab} 0.25s cubic-bezier(0.18, -0.38, 0.28, 1.34) forwards;
`;

const UpdateProduct = ({ data, onClose }: IProps) => {
  const rematchDispatch = useRematchDispatch();

  const { product } = data;
  let type;
  if (product.category.productType === 'OBJECT') {
    type = 'objects';
  } else {
    type = 'services';
  }

  const initialState: IState = {
    checked: type,
    images: product.images,
    serviceType: product.serviceRequestType,
    isGift: product.isFree,
    selectedCategoryId: product.category.id,
    categories: [],
    price: product.isFree ? undefined : product.price.value,
    title: product.title,
    description: product.description,
    priceType: product.price ? product.price.priceType : null,
  };

  const reducer = (state: IState, action: Action): IState => {
    switch (action.type) {
      case 'CHECK_TYPE':
        return { ...state, checked: action.payload };
      case 'SERVICE_TYPE':
        return { ...state, serviceType: action.payload };
      case 'ADD_IMAGE':
        return { ...state, images: [...state.images, action.payload] };
      case 'UPDATE_IMAGE': {
        const { index, file } = action.payload;
        const newImgs = [...state.images];
        newImgs[index] = file;
        return { ...state, images: newImgs };
      }
      case 'REMOVE_IMAGE': {
        const idx = action.payload;
        return {
          ...state,
          images: state.images.filter((_el, index) => idx !== index),
        };
      }
      case 'CHANGE_PRICE':
        return { ...state, price: action.payload };
      case 'CHANGE_PRICE_TYPE':
        return { ...state, priceType: action.payload };
      case 'SET_CATEGORIES':
        return {
          ...state,
          categories: action.payload,
        };
      case 'SELECT_CATEGORY':
        return { ...state, selectedCategoryId: action.payload };
      case 'SET_IS_GIFT':
        return { ...state, isGift: action.payload };
      case 'SET_TITLE':
        return { ...state, title: action.payload };
      case 'SET_DESCRIPTION':
        return { ...state, description: action.payload };
      case 'RESET':
        return initialState;
      default:
        return state;
    }
  };

  const priceTypes = [
    { value: 'PER_HOUR', label: `/Heure`, type: 'select' },
    { value: 'PER_DAY', label: `/Jour`, type: 'select' },
    { value: 'ON_REQUEST', label: `Sur demande`, type: 'radio' },
  ];

  const [
    {
      checked,
      serviceType,
      images,
      price,
      isGift,
      selectedCategoryId,
      categories,
      title,
      description,
      priceType,
    },
    reactDispatch,
  ] = useReducer(reducer, initialState);

  const schema = yup.object().shape({
    title: yup.string().required("Titre d'annonce requis"),
    description: yup.string().required('Description requise'),
    selectCat: yup.string().required('Veuillez selectionner une catégorie'),
  });

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      title,
      selectCat: selectedCategoryId,
      description,
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const [tab, setTab] = useState<'info' | 'photo'>('info');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [priceTypeChoice, setPriceTypeChoice] = useState<IState['priceType']>(
    product.price ? product.price.priceType : null,
  );
  const [priceTypeTime, setPriceTypeTime] =
    useState<IState['priceType']>('PER_HOUR');

  const getCategories = async () => {
    const { data: categoriesData } =
      await MarketAPI.getProductCategoriesByProductType({
        productType:
          checked === 'objects' ? PRODUCT_TYPE.OBJECT : PRODUCT_TYPE.SERVICE,
      });
    reactDispatch({ type: 'SET_CATEGORIES', payload: categoriesData });
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    onPriceTypeChange(priceTypeChoice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceTypeChoice]);

  const onModalClose = () => {
    reactDispatch({ type: 'RESET' });
    onClose();
  };

  const onAddImage = async (file: File) => {
    setIsLoading(true);
    const productId = product.id;
    reactDispatch({ type: 'ADD_IMAGE', payload: file });
    try {
      await MarketAPI.addImageToProduct({ productId: product.id, file })
        .then(() => toast.success(`L'image a bien été ajoutée`))
        .catch(() => {
          throw imageUpload.error;
        });
      rematchDispatch.products.getSelectedProduct({ productId });
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  const onUpdateImage = (file: File, index: number) => {
    reactDispatch({
      type: 'UPDATE_IMAGE',
      payload: {
        index,
        file,
      },
    });
  };

  const onRemoveImage = (index: number, img: any) => {
    const productId = product.id;
    reactDispatch({ type: 'REMOVE_IMAGE', payload: index });
    setIsLoading(true);
    MarketAPI.deleteImageProduct({
      productId: product.id,
      thumbnail: img?.thumbnail ?? '',
    })
      .then(() => {
        rematchDispatch.products.getSelectedProduct({ productId });
        toast.success('Image supprimée');
        setIsLoading(false);
      })
      .catch(() => {
        toast.error(imageDelete);
        setIsLoading(false);
      });
  };

  const onPriceChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;

    if (value) {
      reactDispatch({ type: 'CHANGE_PRICE', payload: parseFloat(value) });
    } else {
      reactDispatch({ type: 'CHANGE_PRICE', payload: 0 });
    }
  };

  const onPriceTypeChange = (value: IState['priceType']) => {
    if (value) {
      reactDispatch({ type: 'CHANGE_PRICE_TYPE', payload: value });
    } else {
      reactDispatch({ type: 'CHANGE_PRICE_TYPE', payload: null });
    }
  };

  const onPriceTypeSelectChange = (
    evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { value } = evt.target;

    if (value === 'PER_HOUR' || value === 'PER_DAY' || value === 'ON_REQUEST') {
      setPriceTypeChoice(value);
      if (value !== 'ON_REQUEST') setPriceTypeTime(value);
      reactDispatch({ type: 'SET_IS_GIFT', payload: false });
    } else if (value === 'IS_GIFT') {
      setPriceTypeChoice(value);
      reactDispatch({ type: 'SET_IS_GIFT', payload: !isGift });
    }
  };

  const onPublish = async () => {
    const productId = product.id;
    setIsLoading(true);
    if (checked === 'objects') {
      try {
        if (!isGift && price === 0) throw priceError.error;

        await MarketAPI.updateMarketProduct({
          productId: product.id,
          data: {
            title,
            description,
            categoryId: selectedCategoryId!,
            isFree: isGift,
            price: isGift ? undefined : price,
          },
        }).catch(() => {
          throw productUpdate.error;
        });

        rematchDispatch.products.getSelectedProduct({ productId });
        onModalClose();
        toast.success(productUpdate.success);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
    }
    if (checked === 'services') {
      try {
        if (!isGift && price === 0 && priceType !== 'ON_REQUEST')
          throw priceError.error;

        await MarketAPI.updateMarketService({
          productId: product.id,
          data: {
            title,
            description,
            categoryId: selectedCategoryId!,
            serviceRequestType: serviceType,
            isFree: isGift,
            price: isGift || priceType === 'ON_REQUEST' ? null : price,
            priceType:
              isGift || priceType === 'IS_GIFT' ? undefined : priceType,
          },
        }).catch(() => {
          throw productUpdate.error;
        });
        rematchDispatch.products.getSelectedProduct({ productId });
        onModalClose();
        toast.success(productUpdate.success);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(error);
      }
    }
  };

  const onChangeTitle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    reactDispatch({ type: 'SET_TITLE', payload: evt.target.value });
  };

  const onChangeDescription = (evt: React.ChangeEvent<HTMLInputElement>) => {
    reactDispatch({ type: 'SET_DESCRIPTION', payload: evt.target.value });
  };

  const onChangeCategory = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    reactDispatch({ type: 'SELECT_CATEGORY', payload: evt.target.value });
  };

  return (
    <BlockUi blocking={isLoading}>
      <div className="modal-header modal-header-market">
        {checked === 'services' ? (
          <div className="modal-title">Modifier mon annonce</div>
        ) : (
          <ul className="flex column-span-2 row-start-1">
            <li
              className={`f3-xs px-2transition-1 py-2 roboto f2 mr-5 cursor-pointer ${
                tab === 'info'
                  ? 'bw-0 text-orange-dark font-bold bb-w-3 bs-solid bc-orange-dark'
                  : 'text-purple-grey'
              } `}
              onClick={() => setTab('info')}
            >
              Mon annonces
            </li>
            <li
              className={`f3-xs px-2transition-1 py-2 roboto f2 mr-5 cursor-pointer ${
                tab === 'photo'
                  ? 'bw-0 text-orange-dark font-bold bb-w-3 bs-solid bc-orange-dark'
                  : 'text-purple-grey'
              } `}
              onClick={() => setTab('photo')}
            >
              Photos
            </li>
          </ul>
        )}
      </div>
      <div className="modal-body modal-body-market">
        {checked === 'services' || tab === 'info' ? (
          <div className="route-wrapper">
            <Stack spacing="md">
              {checked === 'services' && (
                <Box
                  borderStyle="solid"
                  borderTopWidth={1}
                  borderBottomWidth={1}
                  borderColor="grey"
                  mb={8}
                  py={8}
                >
                  <div className="cursor-pointer w-fit">
                    <RadioButton
                      label="Proposer un service"
                      checked={serviceType === 'OFFER'}
                      onCheck={() =>
                        reactDispatch({
                          type: 'SERVICE_TYPE',
                          payload: 'OFFER',
                        })
                      }
                      mb={12}
                      flexDir="row-reverse"
                      justifyContent="flex-end"
                      w="fit-content"
                    />
                  </div>
                  <div className="cursor-pointer w-fit">
                    <RadioButton
                      label="Demander un service"
                      checked={serviceType === 'REQUEST'}
                      onCheck={() =>
                        reactDispatch({
                          type: 'SERVICE_TYPE',
                          payload: 'REQUEST',
                        })
                      }
                      flexDir="row-reverse"
                      justifyContent="flex-end"
                      w="fit-content"
                    />
                  </div>
                </Box>
              )}
              <Box>
                {!!errors.selectCat && errors.selectCat?.message && (
                  <Text color="red" fontSize="sm" mb={0} mt={2}>
                    {errors.selectCat?.message}
                  </Text>
                )}
                <select
                  className="p-3 bc-grey f4 mb-5"
                  value={selectedCategoryId}
                  onChange={onChangeCategory}
                  name="selectCat"
                  ref={register({ required: true })}
                >
                  <option value="">Modifier la catégorie</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </Box>
              <Input
                label="Titre de votre annonce...*"
                placeholder="Titre de votre annonce..."
                onChange={onChangeTitle}
                name="title"
                value={title}
                hasError={!!errors.title}
                errorMessage={errors.title?.message}
                ref={register({ required: true })}
              />
              <Input
                label={
                  checked === 'services'
                    ? 'Décrivez le service demandé ou voulu*'
                    : 'Décrivez le bien vendu ou donné*'
                }
                placeholder="Décrivez le bien vendu ou donné"
                rows={5}
                multiline
                onChange={onChangeDescription}
                name="description"
                value={description}
                hasError={!!errors.description}
                errorMessage={errors.description?.message}
                ref={register({ required: true })}
                withAutoSize
                minLength={3}
                maxLength={400}
                // @ts-ignore
                minRows={4}
              />
              <Stack spacing="xl">
                {checked === 'services' ? (
                  <Box
                    flexDir="column"
                    borderStyle="solid"
                    borderTopWidth={1}
                    borderColor="grey"
                    py={15}
                  >
                    <Text color="purple" mr={10} mb={8}>
                      Prix*
                    </Text>
                    <Box
                      flexDir="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <div className="flex align-center">
                        <Input
                          type="number"
                          placeholder="10€"
                          disabled={isGift || priceType === 'ON_REQUEST'}
                          min="0.01"
                          step="0.01"
                          value={price! > 0 ? price! : undefined}
                          onChange={onPriceChange}
                          name="price"
                        />
                        <Text color="purple" mx={10} mb={16}>
                          €
                        </Text>
                        <select
                          className="p-2 bc-grey f4 mb-4 br-5 h-10"
                          onChange={onPriceTypeSelectChange}
                        >
                          {priceTypes
                            .filter((type) => type.type === 'select')
                            .map((type, index) => (
                              <option key={`price-${index}`} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                        </select>
                      </div>
                      <label className="radio-button ml-2 mb-4">
                        <input
                          type="radio"
                          checked={
                            priceType === 'PER_HOUR' || priceType === 'PER_DAY'
                          }
                          onChange={onPriceTypeSelectChange}
                          name={'sort'}
                          // @ts-ignore
                          // @ts-nocheck
                          value={priceTypeTime}
                        />
                        <span className="radio-background" />
                      </label>
                    </Box>
                    {priceTypes
                      .filter((type) => type.type === 'radio')
                      .map((type, index) => (
                        <Box
                          flexDir="row"
                          alignItems="center"
                          justifyContent="space-between"
                          key={`price-${index}`}
                        >
                          <Text color="purple" mr={10} mb={16}>
                            {type.label}
                          </Text>
                          <label className="radio-button ml-2 mb-4">
                            <input
                              type="radio"
                              checked={priceType === 'ON_REQUEST'}
                              onChange={onPriceTypeSelectChange}
                              name={'sort'}
                              value={type.value}
                            />
                            <span className="radio-background" />
                          </label>
                        </Box>
                      ))}
                    <Box
                      flexDir="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text color="purple" mr={10} mb={16}>
                        Bénévolat
                      </Text>
                      <label className="radio-button ml-2 mb-4">
                        <input
                          type="radio"
                          checked={priceType === 'IS_GIFT' || isGift}
                          onChange={onPriceTypeSelectChange}
                          name={'sort'}
                          value={'IS_GIFT'}
                        />
                        <span className="radio-background" />
                      </label>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    flexDir="column"
                    borderStyle="solid"
                    borderTopWidth={1}
                    borderColor="grey"
                    py={15}
                  >
                    <Text color="purple" mr={10} mb={8}>
                      Prix*
                    </Text>
                    <Box
                      flexDir="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <div className="flex align-center">
                        <Input
                          type="number"
                          placeholder="10€"
                          disabled={isGift}
                          min="0.01"
                          step="0.01"
                          value={price! > 0 ? price! : undefined}
                          onChange={onPriceChange}
                          name="price"
                        />
                        <Text color="purple" mx={10} mb={16}>
                          €
                        </Text>
                      </div>
                      <label className="radio-button ml-2 mb-4">
                        <input
                          type="radio"
                          checked={
                            priceType === 'PER_HOUR' ||
                            priceType === 'PER_DAY' ||
                            !isGift
                          }
                          onChange={onPriceTypeSelectChange}
                          name={'sort'}
                          // @ts-ignore
                          // @ts-nocheck
                          value={priceTypeTime}
                        />
                        <span className="radio-background" />
                      </label>
                    </Box>
                    <Box
                      flexDir="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text color="purple" mr={10} mb={16}>
                        Don
                      </Text>
                      <label className="radio-button ml-2 mb-4">
                        <input
                          type="radio"
                          checked={priceType === 'IS_GIFT' || isGift}
                          onChange={onPriceTypeSelectChange}
                          name={'sort'}
                          value={'IS_GIFT'}
                        />
                        <span className="radio-background" />
                      </label>
                    </Box>
                  </Box>
                )}
              </Stack>
            </Stack>
          </div>
        ) : (
          <TabAnim>
            {' '}
            {/* route-wrapper wont work */}
            <Box>
              <Text color="purpleGrey" fontSize="md">
                Photos
              </Text>
              <Text color="purpleGrey" fontWeight="300" fontSize="sm">
                Les biens ayant plusieurs photo de bonne qualité ont plus de
                chance d’être vendu ! (formats supportés : png/jpg)
              </Text>
              <Box mt={20}>
                <Stack flexDir="row" spacing={20} flexWrap="wrap">
                  {images.map((img, index) => (
                    <Box key={index} mb={20}>
                      <PhotoPicker
                        // @ts-ignore
                        source={img.thumbnail ? img.thumbnail : img}
                        onChange={(file) => onUpdateImage(file, index)}
                        onRemove={() => onRemoveImage(index, img)}
                      />
                    </Box>
                  ))}
                  <Box>
                    <PhotoPicker onChange={onAddImage} />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </TabAnim>
        )}
        {tab === 'info' && (
          <div className="modal-footer bs-solid bc-grey bt-w-1 bw-0 b-0 w-100-xs bg-grey-light z-index-8">
            <Stack flexDir="row" justifyContent="flex-end" spacing={16} px={40}>
              <PlainButton
                variant="secondary"
                title="Annuler"
                onPress={onModalClose}
              />
              <PlainButton
                variant="primary"
                title="Modifier"
                onPress={handleSubmit(onPublish)}
              />
            </Stack>
          </div>
        )}
      </div>
    </BlockUi>
  );
};

export default UpdateProduct;
