import React, { useState } from 'react';
import { Box } from '@alacaza/core/src/components';
import { useDimensions } from '@alacaza/core/src/hooks';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import BorderedButton from '@alacaza/core/src/components/buttons/BorderedButton';
import IconButton from '@alacaza/core/src/components/buttons/IconButton';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import { InviteSelectors } from '@alacaza/core/src/redux';
import BlockUi from 'react-block-ui';
import Input from '../Input';

const SendEmail = () => {
  const [inviteSent, setInviteSent] = useState(false);
  const [contactList, setContactList] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const { isTablet } = useDimensions();

  const dispatch = useRematchDispatch();
  const inviteErrors = useSelector(InviteSelectors.getInviteErrors);

  const removeEmail = (email) => {
    const newList = contactList.filter((contact) => contact !== email);
    setContactList(newList);
  };

  const isValid = (email) => {
    if (contactList.includes(email)) {
      setError(`${email} a déjà été ajouté à votre liste.`);
      return false;
    } if (!/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email)) {
      setError(`${email} n'est pas une adresse valide.`);
      return false;
    }
    return true;
  };

  const checkAndAdd = () => {
    const value = email.trim();
    if (value && isValid(value)) {
      setContactList([...contactList, value]);
      setError('');
      setEmail('');
    }
  };
  const handleKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      checkAndAdd();
    }
  };

  const sendAndReset = () => {
    setLoading(true);
    dispatch.invite
      .sendPropertyInvites({ emails: contactList })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setContactList([]);
    setInviteSent(true);
  };

  return (
    <BlockUi tag="div" className="w-full" blocking={isLoading}>
      <Box
        flex={1}
        w="100%"
        maxW={isTablet ? 600 : undefined}
        style={{ flexBasis: 'auto' }}
      >
        <p className="text-purple-grey f5 text-align-center">
          Ou envoyez des invitations directement par email
        </p>
        {inviteSent && !inviteErrors ? (
          <>
            <p className="text-purple-grey font-bold f4 text-align-center">
              Vos invitations ont bien été envoyées!
            </p>
            <BorderedButton
              fontSize={'md'}
              bg={'greyLight'}
              borderWidth={1}
              borderColor="orange"
              title="Envoyer d'autres invitations"
              w={'100%'}
              onPress={() => {
                setInviteSent(false);
                setContactList([]);
              }}
            ></BorderedButton>
          </>
        ) : (
          <>
            <Input
              onChange={(e) => setEmail(e.currentTarget.value)}
              onKeyDown={handleKeyDown}
              value={email}
            />
            <IconButton
              text="Ajouter un email"
              color="purple"
              onPress={checkAndAdd}
              icon="plus"
              justifyContent="flex-end"
              pb="lg"
              mt="-10px"
            />
            {error && <p className="text-red">{error}</p>}
            {contactList.length > 0 && (
              <Box
                flexDir="row"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                pb="lg"
              >
                {contactList.map((contact) => (
                  <Box p="md">
                    <PlainButton
                      key={contact}
                      bg="purpleGrey"
                      borderWidth={0}
                      rounded="xl"
                      variant="primary"
                      title={`${contact  } X`}
                      p="xs"
                      onPress={() => removeEmail(contact)}
                    />
                  </Box>
                ))}
              </Box>
            )}

            <PlainButton
              variant="primary"
              title="Envoyer les invitations"
              onPress={sendAndReset}
              w={'100%'}
              disabled={contactList.length === 0}
            />
          </>
        )}
      </Box>
    </BlockUi>
  );
};

export default SendEmail;
