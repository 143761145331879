import React, { useState } from 'react';
import { mixpanel } from '@alacaza/core/src';
import { MarketAPI } from '@alacaza/core/src/api';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import { Link } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from 'img/icons/svg/success.svg';
import UserContactForm from '../../../components/UserContactForm';

interface IProps {
  data: any;
  onClose: () => void;
}

const ContactSeller = ({ data, onClose }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  const onSend = async (message: string) => {
    setIsLoading(true);
    await MarketAPI.sendMessageToSeller({
      productId: data.product.id,
      message,
    })
      .then(() => {
        setIsLoading(false);
        setSent(true);
        const { product } = data;
        mixpanel.track('Contact seller succeed', {
          productId: product.id,
          productTitle: product.title,
          categoryId: product.category?.id,
          categoryName: product.category?.name,
          productType: product.category?.productType,
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {!sent ? (
        <UserContactForm
          user={data.product.user!}
          property={data.product.property}
          onClose={onClose}
          onSend={onSend}
          isLoading={isLoading}
          messageMinLength={3}
          messageMaxLength={400}
        />
      ) : (
        <div className="modal-body modal-body-market text-align-center pt-10">
          <SuccessIcon />

          <div className="text-purple f4 text-align-center py-5">
            Votre message a bien été envoyé !
          </div>

          <div className="mt-3">
            <div className="inline-block mr-2">
              <PlainButton
                variant="secondary"
                title="Fermer"
                onPress={onClose}
              />
            </div>

            <Link to="/messages" onClick={onClose}>
              <PlainButton variant="primary" title="Voir mes messages privés" />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactSeller;
