import React from 'react';
import { UserContactDto } from '@alacaza/core/src/typings';
import { ReactComponent as CrossIcon } from 'img/icons/svg/cross.svg';
import { ReactComponent as UserAdd } from 'img/icons/svg/user-plus-solid.svg';
import UserCard from '../UserCard';

type InviteItemProps = {
  user: UserContactDto;
  selectedUsers: UserContactDto[];
  setSelectedUsers: (value: UserContactDto[]) => void;
  selectedListMode?: boolean;
};

const InviteItem = ({
  user,
  selectedUsers,
  setSelectedUsers,
  selectedListMode = false,
}: InviteItemProps) => {
  const selected =
    selectedUsers.findIndex((selectedUser) => selectedUser.id === user.id) > -1;
  const toggleValue = () => {
    if (selected)
      setSelectedUsers([
        ...selectedUsers.filter((selectedUser) => selectedUser.id !== user.id),
      ]);
    else setSelectedUsers([user, ...selectedUsers]);
  };
  if (!selected || selectedListMode)
    return (
      <>
        {user.id && (
          <div className="py-2 flex justify-between align-center cursor-pointer">
            <div onClick={toggleValue} className="w-full">
              <UserCard user={user} />
            </div>
            {selectedListMode ? (
              <CrossIcon onClick={toggleValue} />
            ) : (
              <UserAdd onClick={toggleValue} style={{ height: 22 }} />
            )}
          </div>
        )}
      </>
    );
  return null;
};

export default InviteItem;
