import React from 'react';
import Input from 'js/components/Input';
import { useFieldArray, useForm } from 'react-hook-form';
import { Box, Text } from '@alacaza/core/src/components';
import { useDimensions } from '@alacaza/core/src/hooks';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { PostDto } from '@alacaza/core/src/typings';
import { ReactComponent as CrossIcon } from 'img/icons/svg/cross.svg';
import handleFormError from 'js/utils/handleFormError';

type PollChoiceFormProps = {
  postDetails: PostDto;
  onClose?: () => void;
};

const PollChoiceForm: React.FC<PollChoiceFormProps> = ({
  postDetails,
  onClose,
}) => {
  const dispatch = useRematchDispatch();
  const { isMobile } = useDimensions();

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      pollOptions: postDetails?.pollOptions ?? [{ label: '' }],
      authorizeMultipleChoices: postDetails?.authorizeMultipleChoices ?? 0,
      authorizeCustomOptions: postDetails?.authorizeCustomOptions ?? 0,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pollOptions',
  });

  const onSubmit = async (data) => {
    try {
      await dispatch.newsfeed.updatePost({
        ...data,
        pollOptions: data.pollOptions.map(pollOptions => ({
          label: pollOptions.label,
        })),
        id: postDetails.id,
      });
      onClose();
    } catch (e) {
      handleFormError(e);
    }
  };

  return (
    <Box
      w={isMobile ? '95vw' : '100%'}
      justifyContent="space-between"
      h={isMobile ? '75%' : undefined}
    >
      <Box p="lg" pt="2xl" w="100%">
        <Box flexDir="row" w="100%" pb="md" alignItems="center">
          <label className="radio-button mr-2">
            <input
              type="checkbox"
              ref={register}
              name="authorizeCustomOptions"
            />
            <span className="radio-background" />
          </label>
          <Text fontSize="md" color="purpleGrey">
            Autoriser des réponses différentes à celles que je propose
          </Text>
        </Box>
        <Box flexDir="row" w="100%" pb="xl">
          <label className="radio-button mr-2">
            <input
              type="checkbox"
              ref={register}
              name="authorizeMultipleChoices"
            />
            <span className="radio-background" />
          </label>
          <Text fontSize="md" color="purpleGrey">
            Autoriser plusieurs réponses par personne
          </Text>
        </Box>
        {fields.map((item, index) => (
          <div className="flex justify-between items-center mb-2">
            <Input
              containerClassName="w-full mb-0"
              placeholder="Option"
              name={`pollOptions[${index}].label`}
              defaultValue={item.label}
              ref={register({ required: true, min: 1 })}
            />
            <CrossIcon
              className="ml-2 cursor-pointer"
              onClick={() => remove(index)}
            />
            <Input
              containerClassName="display-none"
              placeholder="Option"
              name={`pollOptions[${index}].ref`}
              defaultValue={item.ref}
              ref={register}
            />
          </div>
        ))}
        <span
          className="underline cursor-pointer f5 ml-auto mb-2"
          onClick={() => append({ label: '' })}
        >
          Ajouter +
        </span>
        <Box p="lg" flexDir="row">
          <Box flex={1} px="lg">
            <PlainButton
              title="Annuler"
              fullWidth
              variant="secondary"
              onPress={onClose}
            />
          </Box>
          <Box flex={1} px="lg">
            <PlainButton
              title="Mettre à jour"
              fullWidth
              onPress={handleSubmit(onSubmit)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PollChoiceForm;
