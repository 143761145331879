import React from 'react';
import { Box } from '@alacaza/core/src/components';
import { ReactComponent as RightArrow } from 'img/icons/svg/chevron-right.svg';
import { Link } from 'react-router-dom';

type InviteCardsProps = {
  type: 'PROPERTY' | 'ZONE';
  name?: string;
  to: string;
};

const InviteCard = ({ type, name, to }: InviteCardsProps) => (
    <Link to={to}>
      <Box bg="white" flexDir="row" p="lg" rounded="lg" shadow="sm" flex={1}>
        <Box flex={5}>
          <h2 className="f4 text-purple roboto">{name}</h2>
          <p className="text-purple-grey f5">
            {type === 'PROPERTY'
              ? "Facilitez l'inscription des personnes résidant avec vous (voisins, co-locataires, conjoints, etc.)."
              : 'Agrandissez votre communauté en invitant vos voisins de quartier.'}
          </p>
        </Box>
        <Box flex={1} alignItems="center" justifyContent="center">
          <RightArrow style={{ height: 30, width: 30 }} />
        </Box>
      </Box>
    </Link>
  );

export default InviteCard;
