import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as PublicRoomIcon } from 'img/icons/room/public.svg';
import { ReactComponent as PrivateRoomIcon } from 'img/icons/room/private.svg';
import { ReactComponent as RightArrow } from 'img/icons/svg/chevron-right.svg';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { removeFinalSlash } from '@alacaza/core/src/utils/string';

interface IProps {
  handleChange: (boolean) => void;
}

const NewRoomTypeSelect = ({ handleChange }: IProps) => {
  const dispatch = useRematchDispatch();
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="modal-header">
        <div className="modal-title">Nouveau groupe de discussion</div>
      </div>

      <div className="modal-body">
        <div className="modal-subtitle block mb-6 px-10 f4 text-align-center">
          Quel type de discussion
          <br />
          souhaitez-vous démarrer ?
        </div>

        <div
          onClick={() => handleChange(true)}
          className="cursor-pointer flex items-center bg-white py-3 px-3 br-5 bs-solid bw-1 bc-grey box-shadow-4 relative cursor-pointer mt-2 br-10 hover:box-shadow-2"
        >
          <PublicRoomIcon className="overflow-visible mr-3" />
          <div className="text-align-left flew-grow-1 mr-3">
            <span className="f4 font-bold text-purple block">
              Groupe de discussion public
            </span>
            <span className="f5 font-normal text-purple-grey block mt-1">
              Tous les voisins de votre résidence auront accès à cette
              discussion.
            </span>
          </div>
          <RightArrow style={{ height: 30, width: 30 }} />
        </div>

        <div
          onClick={() => handleChange(false)}
          className="cursor-pointer flex items-center bg-white py-3 px-3 br-5 bs-solid bw-1 bc-grey box-shadow-4 relative cursor-pointer mt-2 br-10 hover:box-shadow-2"
        >
          <PrivateRoomIcon className="overflow-visible mr-3" />
          <div className="text-align-left flew-grow-1 mr-3">
            <span className="f4 font-bold text-purple block">
              Groupe de discussion privé
            </span>
            <span className="f5 font-normal text-purple-grey block mt-1">
              Seuls les voisins que vous invitez auront accès à cette
              discussion.
            </span>
          </div>
          <RightArrow style={{ height: 30, width: 30 }} />
        </div>

        <div className="f5 text-purple block mt-20 text-align-center">
          Vous pouvez également envoyer un message privé
          <br />
          directement à l'un de vos voisins
          <PlainButton
            mt={5}
            title="Envoyer un message privé"
            onPress={() => {
              dispatch.app.openModal({
                from: '/property/rooms',
                to: `${removeFinalSlash(history.location.pathname)}/chat`,
                type: 'modal/new-chat',
              });
              history.push(
                `${removeFinalSlash(history.location.pathname)}/chat`,
              );
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewRoomTypeSelect;
