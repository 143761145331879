import React, { useState } from 'react';
import { useDimensions } from '@alacaza/core/src/hooks';
import { Box, Text } from '@alacaza/core/src/components';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import BlockUi from 'react-block-ui';
import handleFormError from 'js/utils/handleFormError';

interface DeletePostProps {
  postId: string;
  onClose: Function;
}

const DeletePost: React.FC<DeletePostProps> = ({ postId, onClose }) => {
  const dispatch = useRematchDispatch();
  const { isMobile } = useDimensions();
  const [isLoading, setIsLoading] = useState(false);

  const deletePost = async () => {
    setIsLoading(true);
    try {
      await dispatch.newsfeed.deletePost(postId);
      onClose('/news');
    } catch (e) {
      handleFormError(e);
    }
    setIsLoading(false);
  };

  return (
    <Box
      p="lg"
      pt="xl"
      h={isMobile ? '100%' : undefined}
      minH={isMobile ? undefined : 300}
      minW={isMobile ? undefined : 300}
      justifyContent="center"
      alignItems="center"
    >
      <Text color="purpleGrey" fontSize="md" pb="md" textAlign="center">
        Êtes-vous certain de vouloir supprimer votre publication ?
      </Text>
      <BlockUi blocking={isLoading}>
        <PlainButton
          onPress={deletePost}
          title="Supprimer"
        />
      </BlockUi>
    </Box>
  );
};

export default DeletePost;
