import React from 'react';
import classNames from 'classnames';
import { Text } from '@alacaza/core/src/components';
import TextareaAutosize from 'react-textarea-autosize';

interface Props extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  renderHelper?: () => JSX.Element;
  labelClassName?: string;
  containerClassName?: string;
  hasError?: boolean;
  errorMessage?: string;
  multiline?: boolean;
  ref?: any;
  messageLength?: number;
  withAutoSize?: boolean;
}

const Input = React.forwardRef(
  (
    {
      label,
      renderHelper,
      labelClassName,
      className,
      name,
      containerClassName,
      hasError,
      errorMessage,
      multiline,
      withAutoSize,
      messageLength,
      ...props
    }: Props,
    ref,
  ) => {
    const InputComp = multiline
      ? withAutoSize
        ? TextareaAutosize
        : 'textarea'
      : 'input';

    return (
      <div className={classNames('form-control', containerClassName)}>
        {!!label && (
          <div className="flex items-center justify-between">
            <label className={classNames(labelClassName)} htmlFor={name}>
              {label}
            </label>
            {props.maxLength && (
              <span className="f5 mb-3 text-purple-grey">
                {messageLength! >= 0 ? messageLength : `${props.value}`.length}{' '}
                / {props.maxLength}
              </span>
            )}
          </div>
        )}
        {/* @ts-ignore */}
        <InputComp
          ref={ref}
          className={classNames(className, { 'with-error': hasError })}
          name={name}
          {...props}
        />
        {hasError && errorMessage && (
          <Text color="red" fontSize="sm" mb={0} mt={0}>
            {errorMessage}
          </Text>
        )}
        {renderHelper && <div className="input-helper">{renderHelper()}</div>}
      </div>
    );
  },
);

export default Input;
