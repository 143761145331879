import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { UserContactDto } from '@alacaza/core/src/typings';
import UserIcon from '@alacaza/core/src/components/user/UserIcon';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { getUserContactsByQuery } from '@alacaza/core/src/api/im';
import { mixpanel } from '@alacaza/core/src';
import SearchBar from '.';

const UserContactsSearch: React.FC = () => {
  const [suggestions, setSuggestions] = useState<UserContactDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useRematchDispatch();
  const history = useHistory();

  const shouldRenderSuggestions = (value) => value.trim().length > 2;

  const loadSuggestions = (value: string) => {
    if (shouldRenderSuggestions(value)) {
      setIsLoading(true);
      getUserContactsByQuery({ query: value })
        .then((response) => {
          setSuggestions(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setSuggestions([]);
          setIsLoading(false);
        });
    }
  };

  const openPrivateMessageModal = (user) => {
    dispatch.app.openModal({
      from: `/messages`,
      to: `/users/${user.id}/chat`,
      type: '',
      data: {
        jid: user.jid,
        user,
        property: { zoneName: user.propertyName },
      },
    });
    mixpanel.track('Show new message form', { toUserId: user.id });
    history.push(`/users/${user.id}/chat`);
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <SearchBar
        activateOnChange
        placeholder="Rechercher un voisin..."
        handleSubmit={(value) => loadSuggestions(value)}
        isLoading={isLoading}
      />
      <ul className="mt-3 suggest-results">
        {suggestions &&
          (suggestions.length > 0 ? (
            suggestions.map((member, index) => (
              <li
                className="flex items-center mt-1 pb-3 cursor-pointer"
                key={`member-${index}`}
                onClick={() => openPrivateMessageModal(member)}
              >
                <UserIcon
                  name={member.shortName!}
                  image={{ icon: member.icon }}
                  type="normal"
                />
                <span
                  className="pl-2 f4 text-purple highlights"
                  dangerouslySetInnerHTML={{
                    // @ts-ignore
                    __html: member.fullName,
                  }}
                />
              </li>
            ))
          ) : (
            <div className="f3 muli font-bold text-purple">
              Aucun résultat...
            </div>
          ))}
      </ul>
    </>
  );
};

export default UserContactsSearch;
