import React from 'react';
import { ReactComponent as SuccessIcon } from 'img/icons/svg/success.svg';

interface Props {
  roomId: string | null;
  onClose: () => void;
}

const NewRoomSucceed = ({ onClose }: Props) => (
    <React.Fragment>
      <div className="modal-header"></div>

      <div className="modal-body">
        <div className="w-full text-align-center mb-6">
          <SuccessIcon />
        </div>
        <div className="f4 text-purple block mb-6 text-align-center">
          Votre groupe de discussion a bien été créé !
        </div>
      </div>

      <div className="modal-footer">
        <div className="flex justify-around px-10 px-5-xs">
          <button className="btn w-full" onClick={onClose}>
            Continuer
          </button>
        </div>
      </div>
    </React.Fragment>
  );

export default NewRoomSucceed;
