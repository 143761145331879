import React, { useEffect, useReducer, useState } from 'react';
import { mixpanel } from '@alacaza/core/src';
import { MarketAPI } from '@alacaza/core/src/api';
import { Box, Stack, Text } from '@alacaza/core/src/components';
import { PRODUCT_TYPE, ProductCategoryDto } from '@alacaza/core/src/typings';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import {
  priceError,
  productPublish,
} from '@alacaza/core/src/messages/market';
import BlockUi from 'react-block-ui';
import { useHistory } from 'react-router';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import Input from '../../../components/Input';
import PhotoPicker from '../../../components/PhotoPicker';
import RadioButton from '../../../components/RadioButton';
import handleFormError from 'js/utils/handleFormError';

interface IState {
  checked: 'objects' | 'services';
  serviceType: 'REQUEST' | 'OFFER';
  images: File[];
  isGift: boolean;
  selectedCategoryId: ProductCategoryDto['id'] | null;
  categories: ProductCategoryDto[];
  price: number | null;
  title: string;
  description: string;
  priceType: 'PER_HOUR' | 'PER_DAY' | 'ON_REQUEST' | 'IS_GIFT' | null;
}

type Action =
  | { type: 'CHECK_TYPE'; payload: IState['checked'] }
  | { type: 'SERVICE_TYPE'; payload: IState['serviceType'] }
  | { type: 'ADD_IMAGE'; payload: File }
  | { type: 'UPDATE_IMAGE'; payload: { index: number; file: File } }
  | { type: 'REMOVE_IMAGE'; payload: number }
  | { type: 'CHANGE_PRICE'; payload: number }
  | { type: 'CHANGE_PRICE_TYPE'; payload: IState['priceType'] }
  | { type: 'SET_CATEGORIES'; payload: ProductCategoryDto[] }
  | { type: 'SELECT_CATEGORY'; payload: ProductCategoryDto['id'] }
  | { type: 'RESET'; payload?: undefined }
  | { type: 'SET_IS_GIFT'; payload: boolean }
  | { type: 'SET_TITLE'; payload: string }
  | { type: 'SET_DESCRIPTION'; payload: string };

const initialState: IState = {
  checked: 'objects',
  serviceType: 'OFFER',
  images: [],
  isGift: false,
  selectedCategoryId: null,
  categories: [],
  price: 0,
  title: '',
  description: '',
  priceType: 'PER_HOUR',
};

const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case 'CHECK_TYPE':
      return { ...state, checked: action.payload };
    case 'SERVICE_TYPE':
      return { ...state, serviceType: action.payload };
    case 'ADD_IMAGE':
      return { ...state, images: [...state.images, action.payload] };
    case 'UPDATE_IMAGE': {
      const { index, file } = action.payload;
      const newImgs = [...state.images];
      newImgs[index] = file;
      return { ...state, images: newImgs };
    }
    case 'REMOVE_IMAGE': {
      const idx = action.payload;
      return {
        ...state,
        images: state.images.filter((_el, index) => idx !== index),
      };
    }
    case 'CHANGE_PRICE':
      return { ...state, price: action.payload };
    case 'CHANGE_PRICE_TYPE':
      return { ...state, priceType: action.payload };
    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: action.payload,
      };
    case 'SELECT_CATEGORY':
      return { ...state, selectedCategoryId: action.payload };
    case 'SET_IS_GIFT':
      return { ...state, isGift: action.payload };
    case 'SET_TITLE':
      return { ...state, title: action.payload };
    case 'SET_DESCRIPTION':
      return { ...state, description: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

const priceTypes = [
  { value: 'PER_HOUR', label: `/Heure`, type: 'select' },
  { value: 'PER_DAY', label: `/Jour`, type: 'select' },
  { value: 'ON_REQUEST', label: `Sur demande`, type: 'radio' },
];

const NewProductForm = () => {
  const [
    {
      checked,
      serviceType,
      price,
      images,
      isGift,
      selectedCategoryId,
      categories,
      title,
      description,
      priceType,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const reduxDispatch = useRematchDispatch();
  const history = useHistory();

  const schema = yup.object().shape({
    title: yup.string().required("Titre d'annonce requis"),
    description: yup.string().required('Description requise'),
    selectCat: yup.string().required('Veuillez selectionner une catégorie'),
  });

  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      title: '',
      selectCat: '',
      description: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const descriptionForm = useWatch({
    control,
    name: 'description',
  });

  const [priceTypeChoice, setPriceTypeChoice] =
    useState<IState['priceType']>('PER_HOUR');
  const [priceTypeTime, setPriceTypeTime] =
    useState<IState['priceType']>('PER_HOUR');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCategories = async () => {
    const { data: categoriesData } =
      await MarketAPI.getProductCategoriesByProductType({
        productType:
          checked === 'objects' ? PRODUCT_TYPE.OBJECT : PRODUCT_TYPE.SERVICE,
      });
    dispatch({ type: 'SET_CATEGORIES', payload: categoriesData });
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    onPriceTypeChange(priceTypeChoice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceTypeChoice]);

  const onModalClose = () => {
    dispatch({ type: 'RESET' });
    history.replace(`/market`);
    reduxDispatch.app.closeModal(`/market`);
  };

  const onAddImage = (file: File) => {
    dispatch({ type: 'ADD_IMAGE', payload: file });
  };

  const onUpdateImage = (file: File, index: number) => {
    dispatch({
      type: 'UPDATE_IMAGE',
      payload: {
        index,
        file,
      },
    });
  };

  const onRemoveImage = (index: number) => {
    dispatch({ type: 'REMOVE_IMAGE', payload: index });
  };

  const onPriceChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;

    if (value) {
      dispatch({ type: 'CHANGE_PRICE', payload: parseFloat(value) });
    } else {
      dispatch({ type: 'CHANGE_PRICE', payload: 0 });
    }
  };

  const onPriceTypeChange = (value: IState['priceType']) => {
    if (value) {
      dispatch({ type: 'CHANGE_PRICE_TYPE', payload: value });
    } else {
      dispatch({ type: 'CHANGE_PRICE_TYPE', payload: null });
    }
  };

  const onPriceTypeSelectChange = (
    evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { value } = evt.target;

    if (value === 'PER_HOUR' || value === 'PER_DAY' || value === 'ON_REQUEST') {
      setPriceTypeChoice(value);
      if (value !== 'ON_REQUEST') setPriceTypeTime(value);
      dispatch({ type: 'SET_IS_GIFT', payload: false });
    } else if (value === 'IS_GIFT') {
      setPriceTypeChoice(value);
      dispatch({ type: 'SET_IS_GIFT', payload: !isGift });
    }
  };

  const onPublish = async () => {
    setIsLoading(true);
    if (checked === 'objects') {
      try {
        if (!isGift && price === 0) {
          throw new Error(priceError.error);
        }

        const product = await reduxDispatch.products.createProduct({
          title,
          description,
          categoryId: selectedCategoryId!,
          isFree: isGift,
          price: isGift ? null : price,
          images,
        });

        mixpanel.track('Add new product', {
          productId: product.id,
          productTitle: product.title,
          categoryId: product.category?.id,
          categoryName: product.category?.name,
          productType: product.category?.productType,
        });

        onModalClose();
        toast.success(productPublish.success);
      } catch (error) {
        handleFormError(error);
      }
    }

    if (checked === 'services') {
      try {
        if (!isGift && price === 0 && priceType !== 'ON_REQUEST') {
          throw new Error(priceError.error);
        }

        await MarketAPI.createMarketService({
          data: {
            title,
            description,
            categoryId: selectedCategoryId!,
            serviceRequestType: serviceType,
            isFree: isGift,
            price: isGift || priceType === 'ON_REQUEST' ? null : price,
            priceType:
              isGift || priceType === 'IS_GIFT' ? undefined : priceType,
          },
        });
        onModalClose();
        toast.success(productPublish.success);
      } catch (error) {
        handleFormError(error);
      }
    }
    setIsLoading(false);
  };

  const onChangeTitle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_TITLE', payload: evt.target.value });
  };

  const onChangeDescription = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_DESCRIPTION', payload: evt.target.value });
  };

  const onChangeCategory = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: 'SELECT_CATEGORY', payload: evt.target.value });
  };

  return (
    <BlockUi blocking={isLoading}>
      <div className="modal-header modal-header-market">
        <div className="modal-title">Nouvelle annonce</div>
      </div>

      <div className="modal-body modal-body-market">
        <Stack spacing="md">
          <Box row mb={12}>
            <div className="cursor-pointer">
              <RadioButton
                label="Biens"
                checked={checked === 'objects'}
                onCheck={() =>
                  dispatch({ type: 'CHECK_TYPE', payload: 'objects' })
                }
                mr="xl"
                flexDir="row"
              />
            </div>
            <div className="cursor-pointer">
              <RadioButton
                label="Services"
                checked={checked === 'services'}
                onCheck={() =>
                  dispatch({ type: 'CHECK_TYPE', payload: 'services' })
                }
                flexDir="row"
              />
            </div>
          </Box>
          {checked === 'services' && (
            <Box
              borderStyle="solid"
              borderTopWidth={1}
              borderBottomWidth={1}
              borderColor="grey"
              mb={8}
              py={8}
            >
              <div className="cursor-pointer w-fit">
                <RadioButton
                  label="Proposer un service"
                  checked={serviceType === 'OFFER'}
                  onCheck={() =>
                    dispatch({ type: 'SERVICE_TYPE', payload: 'OFFER' })
                  }
                  mb={12}
                  flexDir="row-reverse"
                  justifyContent="flex-end"
                  w="fit-content"
                />
              </div>
              <div className="cursor-pointer w-fit">
                <RadioButton
                  label="Demander un service"
                  checked={serviceType === 'REQUEST'}
                  onCheck={() =>
                    dispatch({ type: 'SERVICE_TYPE', payload: 'REQUEST' })
                  }
                  flexDir="row-reverse"
                  justifyContent="flex-end"
                  w="fit-content"
                />
              </div>
            </Box>
          )}
          <Box>
            {!!errors.selectCat && errors.selectCat?.message && (
              <Text color="red" fontSize="sm" mb={0} mt={2}>
                {errors.selectCat?.message}
              </Text>
            )}
            <select
              className="p-3 bc-grey f4 mb-5"
              value={selectedCategoryId || ''}
              onChange={onChangeCategory}
              name="selectCat"
              ref={register({ required: true })}
            >
              <option value="">Choisir une catégorie</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </Box>
          <Input
            label="Titre de votre annonce...*"
            placeholder="Titre de votre annonce..."
            onChange={onChangeTitle}
            name="title"
            hasError={!!errors.title}
            errorMessage={errors.title?.message}
            ref={register({ required: true })}
          />
          <Input
            label={
              checked === 'services'
                ? 'Décrivez le service demandé ou voulu*'
                : 'Décrivez le bien vendu ou donné*'
            }
            placeholder="Décrivez le bien vendu ou donné"
            rows={5}
            multiline
            onChange={onChangeDescription}
            name="description"
            hasError={!!errors.description}
            errorMessage={errors.description?.message}
            ref={register({ required: true })}
            withAutoSize
            minLength={3}
            maxLength={400}
            // @ts-ignore
            minRows={4}
            value={descriptionForm as string}
          />
          <Stack spacing="xl">
            {checked === 'objects' && (
              <Box>
                <Text color="purpleGrey" fontSize="md">
                  Photos
                </Text>
                <Text color="purpleGrey" fontWeight="300" fontSize="sm">
                  Les biens ayant plusieurs photos de bonne qualité ont plus de
                  chance d’être vendu ! (formats supportés : png/jpg)
                </Text>
                <Box mt={20}>
                  <Stack flexDir="row" spacing={20} flexWrap="wrap">
                    {images.map((img, index) => (
                      <Box key={index} mb={20}>
                        <PhotoPicker
                          source={img}
                          onChange={(file) => onUpdateImage(file, index)}
                          onRemove={() => onRemoveImage(index)}
                        />
                      </Box>
                    ))}
                    <Box>
                      <PhotoPicker onChange={onAddImage} />
                    </Box>
                  </Stack>
                </Box>
              </Box>
            )}
            {checked === 'services' ? (
              <Box
                flexDir="column"
                borderStyle="solid"
                borderTopWidth={1}
                borderColor="grey"
                py={15}
              >
                <Text color="purple" mr={10} mb={8}>
                  Prix *
                </Text>
                <Box
                  flexDir="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div className="flex align-center">
                    <Input
                      type="number"
                      placeholder="10€"
                      disabled={isGift || priceType === 'ON_REQUEST'}
                      min="0.01"
                      step="0.01"
                      value={price! > 0 ? price! : undefined}
                      onChange={onPriceChange}
                      name="price"
                    />
                    <Text color="purple" mx={10} mb={16}>
                      €
                    </Text>
                    <select
                      className="p-2 bc-grey f4 mb-4 br-5 h-10"
                      onChange={onPriceTypeSelectChange}
                    >
                      {priceTypes
                        .filter((type) => type.type === 'select')
                        .map((type, index) => (
                          <option key={`price-${index}`} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                    </select>
                  </div>
                  <label className="radio-button ml-2 mb-4">
                    <input
                      type="radio"
                      checked={
                        priceType === 'PER_HOUR' || priceType === 'PER_DAY'
                      }
                      onChange={onPriceTypeSelectChange}
                      name={'sort'}
                      // @ts-ignore
                      // @ts-nocheck
                      value={priceTypeTime}
                    />
                    <span className="radio-background" />
                  </label>
                </Box>
                {priceTypes
                  .filter((type) => type.type === 'radio')
                  .map((type, index) => (
                    <Box
                      flexDir="row"
                      alignItems="center"
                      justifyContent="space-between"
                      key={`price-${index}`}
                    >
                      <Text color="purple" mr={10} mb={16}>
                        {type.label}
                      </Text>
                      <label className="radio-button ml-2 mb-4">
                        <input
                          type="radio"
                          checked={priceType === 'ON_REQUEST'}
                          onChange={onPriceTypeSelectChange}
                          name={'sort'}
                          value={type.value}
                        />
                        <span className="radio-background" />
                      </label>
                    </Box>
                  ))}
                <Box
                  flexDir="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text color="purple" mr={10} mb={16}>
                    Bénévolat
                  </Text>
                  <label className="radio-button ml-2 mb-4">
                    <input
                      type="radio"
                      checked={priceType === 'IS_GIFT' || isGift}
                      onChange={onPriceTypeSelectChange}
                      name={'sort'}
                      value={'IS_GIFT'}
                    />
                    <span className="radio-background" />
                  </label>
                </Box>
              </Box>
            ) : (
              <Box
                flexDir="column"
                borderStyle="solid"
                borderTopWidth={1}
                borderColor="grey"
                py={15}
              >
                <Text color="purple" mr={10} mb={8}>
                  Prix*
                </Text>
                <Box
                  flexDir="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div className="flex align-center">
                    <Input
                      type="number"
                      placeholder="10€"
                      disabled={isGift}
                      min="0.01"
                      step="0.01"
                      value={price! > 0 ? price! : undefined}
                      onChange={onPriceChange}
                      name="price"
                    />
                    <Text color="purple" mx={10} mb={16}>
                      €
                    </Text>
                  </div>
                  <label className="radio-button ml-2 mb-4">
                    <input
                      type="radio"
                      checked={
                        priceType === 'PER_HOUR' || priceType === 'PER_DAY'
                      }
                      onChange={onPriceTypeSelectChange}
                      name={'sort'}
                      // @ts-ignore
                      // @ts-nocheck
                      value={priceTypeTime}
                    />
                    <span className="radio-background" />
                  </label>
                </Box>
                <Box
                  flexDir="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text color="purple" mr={10} mb={16}>
                    Don
                  </Text>
                  <label className="radio-button ml-2 mb-4">
                    <input
                      type="radio"
                      checked={priceType === 'IS_GIFT'}
                      onChange={onPriceTypeSelectChange}
                      name={'sort'}
                      value={'IS_GIFT'}
                    />
                    <span className="radio-background" />
                  </label>
                </Box>
              </Box>
            )}
          </Stack>
        </Stack>
        <div className="modal-footer bs-solid bc-grey bt-w-1 bw-0 b-0 w-100-xs bg-grey-light z-index-8">
          <Stack flexDir="row" justifyContent="flex-end" spacing={16} px={40}>
            <PlainButton
              variant="secondary"
              title="Annuler"
              onPress={onModalClose}
            />
            <PlainButton
              variant="primary"
              title="Publier"
              onPress={handleSubmit(onPublish)}
            />
          </Stack>
        </div>
      </div>
    </BlockUi>
  );
};

export default NewProductForm;
