import React, { useRef } from 'react';
import { ReactComponent as SearchIcon } from 'img/icons/svg/search.svg';
import { SearchLoader } from './searchLoader';

interface SearchBarProps {
  placeholder: string;
  handleSubmit: (value: string) => void;
  searchValue?: string;
  setSearchValue?: (value: string) => void;
  activateOnChange?: boolean;
  isLoading?: boolean;
}

const SearchBar = ({
  placeholder,
  handleSubmit,
  searchValue,
  setSearchValue,
  activateOnChange = false,
  isLoading = false,
}: SearchBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <form
      className="flex-grow-1 mb-0"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <label className="flex items-center common-input bg-white px-3 br-5 bs-solid bw-1 bc-grey hover:box-shadow-4">
        {isLoading ? (
          <SearchLoader className="mr-3" />
        ) : (
          <SearchIcon className="mr-3" width={20} height={20} />
        )}
        {searchValue !== undefined && setSearchValue ? (
          <input
            className="appearance-0 bw-0 text-purple f4 flex-grow-1 py-3 lh-3 outline-0 placeholder-text-grey-lighter"
            placeholder={placeholder}
            ref={inputRef}
            value={searchValue}
            onChange={(event) => {
              if (activateOnChange) {
                handleSubmit(inputRef.current!.value);
              }

              setSearchValue(event.target.value);
            }}
          />
        ) : (
          <input
            className="appearance-0 bw-0 text-purple f4 flex-grow-1 py-3 lh-3 outline-0 placeholder-text-grey-lighter"
            placeholder={placeholder}
            ref={inputRef}
            onChange={() => {
              if (activateOnChange) {
                handleSubmit(inputRef.current!.value);
              }
            }}
          />
        )}
      </label>
    </form>
  );
};

export default SearchBar;
