import React, { useState } from 'react';
import UserInfos from '@alacaza/core/src/components/user/UserInfos';
import { UserDto, PropertyDto } from '@alacaza/core/src/typings';
import { Stack } from '@alacaza/core/src/components';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import BlockUi from 'react-block-ui';
import Input from './Input';

interface IProps {
  user: UserDto;
  property?: PropertyDto;
  onClose: () => void;
  onSend: (message: string) => void;
  isLoading?: boolean;
  messageMinLength: number;
  messageMaxLength: number;
}

const UserContactForm = ({
  user,
  property,
  onClose,
  onSend,
  isLoading,
  messageMinLength = 3,
  messageMaxLength = 400,
}: IProps) => {
  const [message, setMessage] = useState('');

  const onChangeMessage = (evt: any) => {
    setMessage(evt.target.value);
  };

  return (
    <>
      <div className="modal-header modal-header-market">
        <UserInfos user={user!} property={property} />
      </div>

      <div className="modal-body modal-body-market">
        <BlockUi tag="div" blocking={isLoading}>
          <Input
            label="Votre message *"
            placeholder="Votre message..."
            multiline
            onChange={(e) => onChangeMessage(e)}
            name="Message"
            value={message}
            withAutoSize
            minLength={3}
            maxLength={400}
            // @ts-ignore
            minRows={5}
          />
          {/* <div className="absolute text-orange f6 text-align-left">{`${message.length}/${messageMaxLength}`}</div> */}
          <Stack flexDir="row" justifyContent="flex-end" spacing={16}>
            <PlainButton
              variant="secondary"
              title="Annuler"
              onPress={onClose}
            />
            <PlainButton
              variant="primary"
              title="Envoyer"
              onPress={() => onSend(message)}
              disabled={
                !message ||
                message.length < messageMinLength ||
                message.length >= messageMaxLength
              }
            />
          </Stack>
        </BlockUi>
      </div>
    </>
  );
};

export default UserContactForm;
