export const OWNER = 'OWNER';
export const HOMEOWNER = 'HOMEOWNER';
export const TENANT = 'TENANT';

export const residentType: { value: string; label: string }[] = [
  {
    value: OWNER,
    label: 'Propriétaire non-occupant',
  },
  {
    value: HOMEOWNER,
    label: 'Propriétaire occupant',
  },
  {
    value: TENANT,
    label: 'Locataire',
  },
];

export const floorSelect: string[] = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
];
