import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const SearchLoader = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;

  &:after {
    content: ' ';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: ${spin} 1.2s linear infinite;
  }
`;
