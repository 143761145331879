import React, { useRef, useMemo } from 'react';
import { Image, Icon } from 'react-native-magnus';
import TouchableBox from '@alacaza/core/src/components/TouchableBox';
import Text from '@alacaza/core/src/components/Text';

interface IProps {
  source?: File | string;
  onChange: (file: File) => void;
  onRemove?: () => void;
}

const PhotoPicker = ({ source, onChange, onRemove }: IProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onOpenPicker = () => {
    if (!source) {
      inputRef.current?.click();
    }
  };

  const onSelectImageWeb = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!!evt.target && !!evt.target.files) {
      onChange(evt.target.files[0]);

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  const src = useMemo(() => {
    if (source instanceof File) {
      return URL.createObjectURL(source);
    }
    return source;
  }, [source]);

  return (
    <div className="cursor-pointer">
      <TouchableBox
        rounded="br-1"
        style={{ backgroundColor: '#ecebf1' }}
        alignItems="center"
        justifyContent="center"
        w={65}
        h={65}
        onPress={onOpenPicker}
      >
        {!source && <Text fontSize="3xl">+</Text>}
        {source && (
          <>
            <Image
              w="100%"
              h="100%"
              resizeMode="contain"
              source={{ uri: src }}
            />
            <TouchableBox
              position="absolute"
              w={12}
              h={12}
              rounded={6}
              style={{ backgroundColor: 'rgba(39, 30, 66, 0.7)' }}
              right={6}
              top={6}
              onPress={onRemove}
              justifyContent="center"
              alignItems="center"
              p={1}
            >
              <Icon
                fontFamily="Ionicons"
                name="close"
                color="white"
                fontSize="xs"
                w={12}
              />
            </TouchableBox>
          </>
        )}
      </TouchableBox>
      {/* {Platform.OS === 'web' && ( */}
      <input
        ref={inputRef}
        type="file"
        capture="environment"
        accept="user,image/png,image/jpeg,image/gif"
        onChange={onSelectImageWeb}
        hidden
      />
      {/* )} */}
    </div>
  );
};

export default PhotoPicker;
