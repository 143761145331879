import React from 'react';
import { Box } from '@alacaza/core/src/components';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'img/icons/svg/arrow-left.svg';

type InviteMobileHeaderProps = {
  title: string;
  backLink?: string;
};

const PanelHeader = ({ title, backLink }: InviteMobileHeaderProps) => (
    <Box
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
      bg="white"
      px="xl"
      pt="lg"
    >
      <Box flexDir="row" alignItems="center">
        {backLink && (
          <Link to={backLink}>
            <ArrowLeftIcon className="mr-6" />
          </Link>
        )}
        <p className="items-center flex f3 text-purple-dark font-bold roboto">
          {title}
        </p>
      </Box>
    </Box>
  );

export default PanelHeader;
