import React from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookMessengerIcon,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import { Box } from '@alacaza/core/src/components';

type Medias = 'facebook' | 'email' | 'whatsapp';
type SocialShareProps = {
  link: string;
  message: string;
  emailBody: string;
  selectedMedias: Medias[];
};
const SocialShare = ({
  link,
  emailBody,
  message,
  selectedMedias,
}: SocialShareProps) => (
    <Box flexDir="row" justifyContent="space-around" p="lg">
      {selectedMedias.includes('facebook') && (
        <FacebookShareButton url={link} quote={message}>
          <FacebookMessengerIcon size={32} round />
        </FacebookShareButton>
      )}
      {selectedMedias.includes('whatsapp') && (
        <WhatsappShareButton url={link} title={message}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      )}
      {selectedMedias.includes('email') && (
        <EmailShareButton url={link} subject={message} body={emailBody}>
          <EmailIcon size={32} round />
        </EmailShareButton>
      )}
    </Box>
  );

export default SocialShare;
