import React from 'react';
import { RouteProps, Redirect, Route, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { AuthSelectors, AppSelectors } from '@alacaza/core/src';

const PrivateRoute = (props: RouteProps) => {
  const isLogged = useSelector(AuthSelectors.getIsLogged);
  const isRehydrated = useSelector(AppSelectors.getIsRehydrated);
  const { pathname } = useLocation();

  if (!isRehydrated) {
    return null;
  }

  if (!isLogged) {
    return (
      <Redirect
        to={`/login${
          ![
            'logout',
            '/logout',
            'ciao',
            '/ciao',
            '/settings',
            '/settings/me',
          ].includes(pathname)
            ? `?redirect=${pathname}`
            : ''
        }`}
      />
    );
  }

  return <Route {...props} />;
};

export default PrivateRoute;
