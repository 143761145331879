import React, { useState } from 'react';
import Input from 'js/components/Input';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { mixpanel } from '@alacaza/core/src';
import { useDimensions } from '@alacaza/core/src/hooks';
import { Box, Text, Stack } from '@alacaza/core/src/components';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import BlockUi from 'react-block-ui';
import TextareaAutosize from 'react-textarea-autosize';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { PostDto } from '@alacaza/core/src/typings';
import moment from 'moment';
import 'moment/locale/fr';
import PhotoPicker from '../../../../components/PhotoPicker';
import handleFormError from 'js/utils/handleFormError';

type EventPostFormProps = {
  setPostBase?: any;
  setPostPublished?: any;
  isEdit?: boolean;
  postDetails?: PostDto;
  onClose?: () => void;
};

const EventPostForm: React.FC<EventPostFormProps> = ({
  setPostBase,
  setPostPublished,
  postDetails,
  isEdit,
  onClose,
}) => {
  const { isMobile } = useDimensions();
  const dispatch = useRematchDispatch();
  const [bodyLength, setBodyLength] = useState(postDetails?.body?.length || 0);
  const [titleLength, setTitleLength] = useState(
    postDetails?.title?.length || 0,
  );
  const [images, setImages] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    title: yup
      .string()
      .required('Titre de publication requis')
      .min(3, 'Le titre de la publication doit faire au moins 3 charactères.'),
    body: yup
      .string()
      .required('Texte de publication requis')
      .min(10, 'Le texte de publication doit faire au moins 10 caractères.'),
    location: yup
      .string()
      .required(`Le lieu de l'événement est requis`)
      .min(3, 'Le lieu doit contenir au moins 3 caractères.'),
    startDate: yup
      .date()
      .typeError('Veuillez entrer une date de début pour votre évenement')
      .min(new Date(), "Date de l'évenement invalide"),
    endDate: yup
      .date()
      .strict(false)
      .transform((value, originalValue) => {
        if (originalValue === '') {
          return undefined;
        }
        return value;
      })
      .notRequired()
      .min(new Date(), "Date de fin de l'évenement invalide"),
  });

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      title: postDetails?.title ?? '',
      body: postDetails?.body ?? '',
      startDate:
        postDetails?.startDate && !!postDetails.startDate
          ? moment(postDetails.startDate).utc().format('YYYY-MM-DD[T]HH:mm')
          : null,
      endDate:
        postDetails?.endDate && !!postDetails.endDate
          ? moment(postDetails.endDate).utc().format('YYYY-MM-DD[T]HH:mm')
          : null,
      location: postDetails?.location,
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const finish = () => {
    if (isEdit) {
      onClose();
    } else {
      setPostPublished(true);
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      if (isEdit) {
        await dispatch.newsfeed
          .updatePost({ ...data, id: postDetails.id });
        mixpanel.track('Update post', {
          postType: 'EVENT',
          postData: data,
          postId: postDetails?.id,
        });
      } else {
        await dispatch.newsfeed.createPost({
          ...data,
          images,
          postType: 'event',
        });
        finish();
      }
    } catch (e) {
      handleFormError(e);
    }

    setIsLoading(false);
  };

  return (
    <Box justifyContent="space-between" h={isMobile ? '75%' : undefined}>
      <BlockUi blocking={isLoading}>
        <Box p="lg" pt="2xl">
          <Box justifyContent="space-between" flexDir="row">
            <Text color="purpleGrey" fontSize="md" pb="sm">
              Titre de votre publication *
            </Text>
            <Text color="purpleGrey" fontSize="md" pb="sm">
              {titleLength}/100
            </Text>
          </Box>
          <Input
            placeholder="Votre titre..."
            name="title"
            hasError={!!errors.title}
            errorMessage={errors.title?.message}
            ref={register({ required: true })}
            maxLength={100}
            onChange={(e) => setTitleLength(e.currentTarget.value.length)}
          />
          <Box justifyContent="space-between" flexDir="row">
            <Text color="purpleGrey" fontSize="md" pb="sm">
              Publication *
            </Text>
            <Text color="purpleGrey" fontSize="md" pb="sm">
              {bodyLength}/800
            </Text>
          </Box>
          <div className="form-control">
            <TextareaAutosize
              placeholder="Votre publication..."
              onChange={(e) => {
                setBodyLength(e.target.value.length);
              }}
              ref={register({ required: true })}
              minRows={3}
              minLength={3}
              maxLength={800}
              name="body"
            />
            {!!errors.body && errors.body?.message && (
              <Text color="red" fontSize="sm" mb={0} mt={0}>
                {errors.body?.message}
              </Text>
            )}
          </div>
          <Box borderTopColor="grey" borderTopWidth={1} py="md">
            <Box flexDir="row">
              <Box flex={1} p="md">
                <Input
                  label="Date de début *"
                  placeholder="jj/mm/aaaa"
                  type="datetime-local"
                  name="startDate"
                  ref={register}
                  hasError={!!errors.startDate}
                  errorMessage={errors?.startDate?.message || ''}
                />
              </Box>
            </Box>
            <Box flexDir="row">
              <Box flex={1} p="md">
                <Input
                  label="Date de fin"
                  placeholder="jj/mm/aaaa"
                  type="datetime-local"
                  name="endDate"
                  ref={register}
                  hasError={!!errors.endDate}
                  errorMessage={errors?.endDate?.message || ''}
                />
              </Box>
            </Box>
          </Box>
          <Box borderTopColor="grey" borderTopWidth={1} py="xl">
            <Text color="purpleGrey" fontSize="md" pb="md">
              Lieu de l'évènement *
            </Text>
            <Input
              placeholder="Lieu de l'évènement..."
              name="location"
              hasError={!!errors.location}
              errorMessage={errors.location?.message}
              ref={register({ required: true })}
              maxLength={100}
            />
          </Box>
          {!isEdit && (
            <Box mt={20} mb={40}>
              <Text color="purpleGrey" fontSize="md" pb="sm">
                Images
              </Text>
              <Stack flexDir="row" spacing={20} flexWrap="wrap">
                {images.map((img, index) => (
                  <Box key={index} mb={20}>
                    <PhotoPicker
                      source={img}
                      onChange={(file) => {
                        const newImages = [...images];
                        newImages[index] = file;
                        setImages(newImages);
                      }}
                      onRemove={() =>
                        setImages(
                          images.filter(
                            (item, itemIdx) => item && itemIdx !== index,
                          ),
                        )
                      }
                    />
                  </Box>
                ))}
                {images.length < 5 && (
                  <Box>
                    <PhotoPicker
                      onChange={(file) => {
                        setImages([...images, file]);
                      }}
                    />
                  </Box>
                )}
              </Stack>
            </Box>
          )}
        </Box>
        <Box p="lg" flexDir="row" mb={isMobile ? 'sm' : undefined}>
          <Box flex={1} px="lg">
            <PlainButton
              title="Annuler"
              fullWidth
              variant="secondary"
              onPress={() => (!isEdit ? setPostBase() : onClose!())}
            />
          </Box>
          <Box flex={1} px="lg">
            <PlainButton
              title={postDetails?.title ? 'Valider' : 'Publier'}
              fullWidth
              onPress={handleSubmit(onSubmit)}
            />
          </Box>
        </Box>
      </BlockUi>
    </Box>
  );
};

export default EventPostForm;
