import React from 'react';
import { Box } from '@alacaza/core/src/components';
import appleBadge from 'img/app_store_fr.svg';
import googleBadge from 'img/google_play_fr.png';

const localStorageKey = 'invite-download-app-shown';

const DownloadApp = () => {
  const [shouldShow, setShouldShow] = React.useState(() => window.localStorage.getItem(localStorageKey) !== 'true');

  const onClose = React.useCallback(() => {
    setShouldShow(false);
    window.localStorage.setItem(localStorageKey, 'true');
  }, []);

  if (!shouldShow) return <></>;

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 10,
        right: 10,
        zIndex: 99999,
        maxWidth: '100%',
      }}
    >
      <Box flexDir="column" p="xl">
        <div
          className="box-shadow-5 bg-white p-5"
          style={{ borderRadius: 4, maxWidth: 400 }}
        >
          <Box
            flex={1}
            bg="white"
            alignItems="center"
            style={{ flexBasis: 'auto' }}
            justifyContent="space-around"
          >
            <Box>
              <p className="text-purple font-bold f4 m-1">
                Alacaza est disponible sur votre mobile !
              </p>
              <p className="text-purple-grey f4 m-1">
                Téléchargez l’application sur vos stores préférés pour que
                l’expérience soit plus fluide !
              </p>
            </Box>
            <Box
              flex={1}
              pt={12}
              flexDir="row"
              alignItems="center"
              style={{ flexBasis: 'auto' }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=fr.alacaza.clients.resident&gl=FR"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googleBadge}
                  alt="inviter des voisins"
                  style={{ height: 44 }}
                />
              </a>
              <div className="w-4" />
              <a
                href="https://apps.apple.com/fr/app/alacaza/id1567886810"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appleBadge}
                  alt="inviter des voisins"
                  style={{ height: 44 }}
                />
              </a>
            </Box>
          </Box>
          <Box position="absolute" top={10} right={20}>
            <i className="modal-arrow z-index-9" onClick={() => onClose()} />
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default DownloadApp;
