import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store, mixpanel } from '@alacaza/core/src';
import { ThemeProvider } from '@alacaza/core/src/components';
import * as Sentry from '@sentry/react';

import App from 'js/App';
import './less/style.less';
import './iconsFonts';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS_TOKEN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: (integrations) => integrations.filter((integration) =>
    integration.name !== 'Breadcrumbs' && integration.name !== 'TryCatch'
  ),
});

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
  api_host: 'https://api-eu.mixpanel.com',
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <Provider store={store}>
      <Router>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Router>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('app'),
);
