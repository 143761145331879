import React, { useEffect } from 'react';
import { AppSelectors, MeSelectors } from '@alacaza/core/src';
import { useSelector } from 'react-redux';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { InviteSelectors } from '@alacaza/core/src/redux';
import { Box } from '@alacaza/core/src/components';
import PanelHeader from '../../../components/panelHeader';
import SocialShare from '../../../components/invite/socialShare';
import CopyLink from '../../../components/invite/copyLink';
import SendEmail from '../../../components/invite/sendEmail';

const InviteProperty = () => {
  const currentAccount = useSelector(MeSelectors.getCurrentAccount);
  const me = useSelector(MeSelectors.getMeInfo);
  const dispatch = useRematchDispatch();
  const { navigation } = useSelector(AppSelectors.getModal);
  const invitePropertyLink = useSelector(InviteSelectors.getPropertyInviteLink);
  const invitePropertyLinkLoading = useSelector(
    InviteSelectors.getPropertyInviteLinkLoading,
  );

  useEffect(() => {
    if (currentAccount && !invitePropertyLink) {
      dispatch.invite.getPropertyInviteLink();
    }
  }, [
    dispatch.app,
    dispatch.invite,
    invitePropertyLinkLoading,
    currentAccount,
  ]);

  return (
    <>
      <PanelHeader
        title={'Inviter des voisins'}
        backLink={`${navigation.from}/invite/`}
      />

      {!!currentAccount && (
        <Box flexDir="column" p="xl">
          <Box
            flex={1}
            alignItems="center"
            style={{ flexBasis: 'auto' }}
            justifyContent="space-around"
          >
            <Box>
              <p className="text-purple font-bold f4">
                Invitez vos voisins à vous rejoindre sur Alacaza !
              </p>
              <p className="text-purple-grey f4 pb-8 ">
                Attention à ne partager ces invitations qu'avec des personnes
                résidant{' '}
                <span className="font-bold">
                  {currentAccount.propertyName}!
                </span>
              </p>
            </Box>

            <Box pb="md">
              <p className="text-purple-grey f5">
                Vous pouvez partager le lien d'inscription ci-dessous
                directement aux personnes que vous souhaitez inviter.
              </p>
              {me && !invitePropertyLinkLoading && (
                <CopyLink
                  link={invitePropertyLink}
                  loading={invitePropertyLinkLoading}
                />
              )}
            </Box>
          </Box>
          <Box
            flex={1}
            alignItems="center"
            justifyContent="center"
            p="lg"
            style={{ flexBasis: 'auto' }}
          >
            <Box flex={1} pb="xl" style={{ flexBasis: 'auto' }}>
              <p className="text-purple-grey f5 text-align-center">
                Partagez le lien avec vos applications préférées
              </p>
              {me && !invitePropertyLinkLoading && (
                <SocialShare
                  link={invitePropertyLink}
                  message={`${me.fullName} vous invite à rejoindre vos voisins ${currentAccount.propertyName} sur Alacaza !`}
                  emailBody={`Bonjour, \n\nJe vous invite à rejoindre vos voisins ${currentAccount.propertyName} dans le quartier ${currentAccount.zoneName} sur Alacaza !\n\nInscrivez-vous directement en utilisant le lien suivant :\n`}
                  selectedMedias={['facebook', 'email', 'whatsapp']}
                />
              )}
            </Box>
            <SendEmail />
          </Box>
        </Box>
      )}
    </>
  );
};

export default InviteProperty;
