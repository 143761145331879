import { ReactComponent as IconPoll } from '../../../../img/icons/newsfeed/post-type-poll.svg';
import { ReactComponent as IconRecommendation } from '../../../../img/icons/newsfeed/post-type-recommendation.svg';
import { ReactComponent as IconSecurity } from '../../../../img/icons/newsfeed/post-type-security.svg';
import { ReactComponent as IconGen } from '../../../../img/icons/newsfeed/post-type-general.svg';
import { ReactComponent as IconEvent } from '../../../../img/icons/newsfeed/post-type-event.svg';
import { ReactComponent as IconLostFound } from '../../../../img/icons/newsfeed/post-type-lost-found.svg';

export type postCategoryObj = {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      typeTitle?: string | undefined;
    }
  >;
  type: string;
  typeTitle: string;
  description: string;
};

export const postCategories: postCategoryObj[] = [
  {
    icon: IconEvent,
    type: 'EVENT',
    typeTitle: 'Événement',
    description:
      'Organisez un événement avec vos voisins (réunion de quartier, fête des voisins, etc.).',
  },
  {
    icon: IconPoll,
    type: 'POLL',
    typeTitle: 'Sondage',
    description: 'Créez un sondage pour éclairer une prise de décision.',
  },
  {
    icon: IconRecommendation,
    type: 'ADVICE',
    typeTitle: 'Recommandation',
    description:
      'Proposez ou partagez des conseils (médecin, restaurant, etc.).',
  },
  {
    icon: IconLostFound,
    type: 'LOST_FOUND',
    typeTitle: 'Perdu / Trouvé',
    description: 'Déclarez perdu ou trouvé un objet, un animal, etc.',
  },
  {
    icon: IconSecurity,
    type: 'SECURITY',
    typeTitle: 'Sécurité',
    description: 'Alertez vos voisins d’un danger ou d’un risque éventuel.',
  },
  {
    icon: IconGen,
    type: 'GENERAL',
    typeTitle: 'Général',
    description: 'Exprimez vous auprès de vos voisins du quartier ',
  },
];
