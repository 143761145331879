import { AppSelectors, mixpanel } from '@alacaza/core/src';
import { Box } from '@alacaza/core/src/components';
import { useDimensions } from '@alacaza/core/src/hooks';
import PlainButton from '@alacaza/core/src/components/buttons/PlainButton';
import HelloImg from 'img/icons/welcome/hello.png';
import React from 'react';
import { useHistory } from 'react-router';
import useRematchDispatch from '@alacaza/core/src/hooks/useRematchDispatch';
import { useSelector } from 'react-redux';

const Welcome = ({ onClose }) => {
  const { isMobile } = useDimensions();
  const [step] = React.useState<number>(0);
  const history = useHistory();
  const dispatch = useRematchDispatch();
  const { isOpen } = useSelector(AppSelectors.getModal);
  const maxHeight = window.innerHeight * 0.8;

  React.useEffect(() => {
    if (!isOpen) {
      dispatch.app.openModal({
        from: '/',
        to: '/welcome',
        type: '',
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (step === 0) {
      mixpanel.track('Start on boarding');
    } else {
      mixpanel.track('Show on boarding step', {
        step,
      });
    }
  }, [step]);

  return (
    <Box
      p="lg"
      h={isMobile ? '100%' : maxHeight}
      minH={isMobile ? undefined : maxHeight < 600 ? maxHeight : 600}
      minW={isMobile ? undefined : 500}
      maxH={isMobile ? undefined : 850}
      maxW={550}
    >
      <div className="modal-body modal-body-welcome">
        <div className="modal-welcome-content">
          <img src={HelloImg} alt="Hello" className="image-welcome-modal" />
          <div className="modal-welcome-info">
            <div className="modal-welcome-title roboto">
              Bienvenue sur Alacaza !
            </div>
            <div className="modal-welcome-description">
              <p>
                Alacaza est la première application
                <br />
                dédiée à la vie de votre résidence et de votre quartier !
              </p>
              <p className="roboto font-bold">
                Découvrons ensemble les fonctionnalités !
              </p>
            </div>
          </div>
        </div>
        <div className="modal-welcome-footer">
          <div className="button-container">
            <PlainButton
              title="Démarrer la visite"
              w={'auto'}
              onPress={() => {
                dispatch.app.openOnboarding({
                  step: 'STEP_1_PROPERTY_ROOMS',
                });
                history.push('/property/rooms');
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Welcome;
